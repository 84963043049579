import React from 'react';
import Select from 'react-select';
import './Dropdown.scss';

const customStyles = {
  control: styles => ({
    ...styles,
    background: 'transparent',
    cursor: 'pointer',
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '510px',
    background: 'black',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    background: '#3e3742',
    color: `rgba(255,255,255,${state.isDisabled ? 0.3 : 1})`,
    padding: 15,
    '&:hover': {
      background: '#2C272F',
      cursor: state.isDisabled ? 'default' : 'pointer',
    },
  }),
};

export default function Dropdown({ selectOptions, selectDefaultValue, link }) {
  return (
    <Select
      styles={customStyles}
      defaultMenuIsOpen={false}
      isSearchable={true}
      id="dropdown-select"
      options={selectOptions}
      defaultValue={selectDefaultValue}
      onChange={link}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: 'white',
        },
      })}
    ></Select>
  );
}
