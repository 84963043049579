import { cloneElement } from 'react';
import { useResponsive } from 'hooks';

/*
 ** Ajoute la props "mobile: true" à son unique enfant si
 ** on est sur une taille d'écran de mobile
 */
export default function Responsive({ children }) {
  const { isMobile } = useResponsive();

  return cloneElement(children, { mobile: isMobile() });
}
