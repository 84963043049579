import React from 'react';

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const pluralize = (one, many, cond) => (cond > 1 ? many : one);

export const limitWords = (string, limit) => {
  const words = string.split(' ');
  if (words.length < limit) return string;
  return words.slice(0, limit).join(' ') + '...';
};

export const textEllipsis = (text, limit) => {
  if (!text) return '';
  if (text.length <= limit) return text;
  return text.slice(0, limit - 3) + '...'; // -3 for '...'
};

export function formatText(text) {
  if (!text) return null;
  return text.split('\n').map((item, i) => {
    return <p key={i}>{item}</p>;
  });
}
