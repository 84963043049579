import React from 'react';

import { useResponsive } from 'hooks';

import CompactMenu from './CompactMenu';
import LargeMenu from './LargeMenu';

import 'rc-slider/assets/index.css';
import './ReaderMenu.scss';

export default function ReaderMenu(props) {
  const window = useResponsive();
  return (
    <div id="ReaderMenu">
      {!window.is('lg') && <CompactMenu {...props} />}
      {window.is('lg') && <LargeMenu {...props} />}
    </div>
  );
}
