import { ResponsiveText } from 'UI';
import './TitleHeader.scss';

function TitleHeader({ title, subtitle }) {
  return (
    <div className="titleHeader">
      <ResponsiveText type="display" bold>
        {title}
      </ResponsiveText>
      <ResponsiveText type="text" size="large" className="subtitleText">
        {subtitle}
      </ResponsiveText>
    </div>
  );
}
export default TitleHeader;
