import React from 'react';
import PropTypes from 'prop-types';
import './toggleSwitch.scss';

const ToggleSwitch = ({ onChange }) => {
  return (
    <div className="mio-toggle">
      <input type="checkbox" id="toggle" onChange={onChange} />
      <label htmlFor="toggle" className="switch" />
    </div>
  );
};

ToggleSwitch.propTypes = {
  onChange: PropTypes.func,
};

export default ToggleSwitch;
