import { useEffect, useState } from 'react';

import { useBundles } from 'hooks';

export default function useRecommend(id) {
  const [recommendations, setRecommendations] = useState([]);
  const { bundles } = useBundles();

  const bundleString = bundles
    .map(bundle => `${bundle._id}:${bundle.access}`)
    .join(',');
  useEffect(() => {
    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/search/recommend/${id}?bundles=${bundleString}`
      )
        .then(response => response.json())
        .then(data => {
          setRecommendations(data.hits);
        });
    } catch (e) {
      console.log('Recommend error', e);
    }
  }, [id, bundleString]);

  return { recommendations, loading: recommendations.length === 0 };
}
