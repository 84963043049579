import React from 'react';

class GraphqlShield extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }
  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;
    if (hasError && error.graphQLErrors) {
      const errors = error.graphQLErrors.map(e => e.message);
      return children(errors);
    }
    return children();
  }
}

export default GraphqlShield;
