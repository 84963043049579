import React from 'react';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';

import { Subscription } from 'Components';
import { OnboardingBackground } from 'UI';

function SubscriptionPage({ initialRouterConfig, isSuccess, currentLocation }) {
  const history = useHistory();
  const { state = {}, search } = useLocation();
  const query = new URLSearchParams(search);
  const hasDiscoveryPlan =
    query.get('hasDiscoveryPlan') === 'false' ? false : state.hasDiscoveryPlan;
  return (
    <OnboardingBackground isLarge id="mio-loginPage" isSuccess={isSuccess}>
      <Subscription
        onSubmit={() => history.push('/')}
        hasDiscoveryPlan={hasDiscoveryPlan}
        initialRouterConfig={initialRouterConfig}
        currentLocation={currentLocation}
      />
    </OnboardingBackground>
  );
}

export default function SubscriptionPageRouter() {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <Route exact path={[match.path, `${match.path}/cancelled`]}>
        <SubscriptionPage />
      </Route>
      <Route path={`${match.path}/success`}>
        <SubscriptionPage
          initialRouterConfig={['/success/stripe']}
          currentLocation={location}
          isSuccess
        />
      </Route>
    </Switch>
  );
}
