import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import useQuery from 'hooks/useQuery';
import { NotFound } from 'Components';

const GQL_GET_MANGA_BY_EAN = gql`
  query getMangaByEan($ean: String) {
    manga(ean: $ean) {
      _id
      slug
    }
  }
`;

function EANRedirector(props) {
  const { ean } = useParams();
  const { loading, data } = useQuery(GQL_GET_MANGA_BY_EAN, {
    variables: {
      ean,
    },
  });
  if (!loading && data.manga)
    return <Redirect to={`/lire/${data.manga.slug}`} />;
  if (!loading && !data.manga) return <NotFound />;
  return null;
}

export default EANRedirector;
