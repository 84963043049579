import { useFeatureFlags } from 'hooks';

export default function useOffers(variant) {
  const flags = useFeatureFlags(['offers', 'promotions']);

  let defaultOffers = [];
  let promotions = [];
  if (flags.offers && flags.offers.enabled && flags.offers.value) {
    try {
      defaultOffers = JSON.parse(flags.offers.value);
    } catch (e) {
      console.error('couldnt retrieve offers', e);
    }
  }
  if (flags.promotions && flags.promotions.enabled && flags.promotions.value) {
    try {
      promotions = JSON.parse(flags.promotions.value);
    } catch (e) {
      console.error('could not retrieve promotions');
    }
  }
  const limitedOffers = promotions
    .filter(promo => !defaultOffers.find(offer => offer.plan === promo.plan))
    .filter(promo => !!promo[variant])
    .map(promo => ({
      ...promo[variant],
      plan: promo.plan,
      position: promo.position,
    }));
  const mergedOffers = defaultOffers
    .map(offer => {
      const offerPromotion = promotions.find(
        promo => promo.plan === offer.plan
      );
      if ((offerPromotion && !offerPromotion[variant]) || !offer[variant])
        return null;
      return offerPromotion
        ? {
          ...offerPromotion[variant],
          plan: offerPromotion.plan,
          planId: offerPromotion.planId,
          position: offerPromotion.position,
          price: offerPromotion.price,
        }
        : {
          ...offer[variant],
          plan: offer.plan,
          planId: offer.planId,
          position: offer.position,
          price: offer.price,
        };
    })
    .concat(limitedOffers)
    .filter(offer => !!offer)
    .sort((a, b) => (a.position || 100) - (b.position || 100));
  return mergedOffers;
}
