import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import './AppStoreButton.scss';

const AppStoreButton = props => {
  const { type, className, ...buttonProps } = props;

  const playStoreImg = 'https://cdn.mangas.io/landing/cta-google-play-min.svg';
  const appStoreImg = 'https://cdn.mangas.io/landing/cta-app-store-min.svg';
  const passCultureImg =
    'https://cdn.mangas.io/landing/pass-culture-button.svg';

  const getStoreImg = () => {
    if (type === 'android') return playStoreImg;
    if (type === 'ios') return appStoreImg;
    if (type === 'passCulture') return passCultureImg;

    return playStoreImg;
  };

  return (
    <button
      type="button"
      className={classnames('storeButton', 'mio-button', className, {
        android: type === 'android',
        ios: type === 'ios',
        passCulture: type === 'passCulture',
      })}
      {...buttonProps}
    >
      <img
        src={getStoreImg(type)}
        width="160"
        height="46"
        alt={
          type === 'passCulture'
            ? 'Aller sur le site Pass Culture'
            : 'Télécharger sur ' +
              (type === 'ios' ? 'App Store' : 'Google Play Store')
        }
      />
    </button>
  );
};

AppStoreButton.propTypes = {
  type: PropTypes.oneOf(['android', 'ios', 'passCulture']),
};

AppStoreButton.defaultProps = {
  type: 'android',
};

export default AppStoreButton;
