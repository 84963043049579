import React from 'react';
import classnames from 'classnames';

import { CustomErrorBoundary } from 'utils';
import { useAuth, useOffers } from 'hooks';
import { PricingCardNew } from 'Components';

import './OfferLine.scss';

function OfferLine() {
  const { isAuthenticated } = useAuth();
  const ctaLink = isAuthenticated ? '/abonnement' : '/signup';
  const offers = useOffers('card');

  return (
    <>
      <div className="subscription">
        <div id="panels" className="panels">
          {offers.map(offer => (
            <div className="panel" key={offer.plan}>
              <PricingCardNew
                className={classnames('card', offer.className)}
                header={offer.header}
                title={
                  <p className="price">
                    <span>{offer.title.value}</span>
                    {offer.title.underlines.map((line, i) => (
                      <small key={i}>{line}</small>
                    ))}
                  </p>
                }
                chipText={offer.pill}
                link={ctaLink}
                linkText="J'active mes 7 jours gratuits"
                version={offer.version}
              />
            </div>
          ))}
          <div className="panel">
            <PricingCardNew
              className="card"
              header="Pass culture"
              title={
                <div className="logo">
                  <img
                    src="https://cdn.mangas.io/images/pass-culture-min.svg"
                    alt="Logo du Pass Culture"
                    width="230"
                    height="78"
                  />
                </div>
              }
              chipText="Pour les 18 ans et moins !"
              subtitle="Bénéficie gratuitement de ton abonnement Mangas.io !"
              link="https://passculture.app/recherche/resultats?query=%22mangas.io%22"
              linkText="Découvrir l’offre"
              version="passCulture"
            />
          </div>
        </div>
      </div>

      <div className="box-notice">
        <img
          src="https://cdn.mangas.io/landing/ic_outline-lightbulb-min.svg"
          width="24"
          height="24"
          alt="lightbulb"
          className="icon"
        />
        <span>
          Toutes nos offres sont sans engagement et résiliables quand tu le
          souhaites.
        </span>
      </div>
    </>
  );
}

export default function OfferLineShielded() {
  return (
    <CustomErrorBoundary>
      <OfferLine />
    </CustomErrorBoundary>
  );
}
