import React from 'react';
import { oneOf, string } from 'prop-types';
import classnames from 'classnames';

import './SocialIcon.scss';

const SocialIcon = props => {
  const { link, type, className } = props;

  const icons = {
    facebook: 'https://cdn.mangas.io/images/facebook.png',
    twitter: 'https://cdn.mangas.io/images/twitter.png',
    instagram: 'https://cdn.mangas.io/images/instagram.png',
    tiktok: 'https://cdn.mangas.io/images/tiktok.png',
    twitch: 'https://cdn.mangas.io/images/twitch.png',
    youtube: 'https://cdn.mangas.io/youtube.png',
    linkedin: 'https://cdn.mangas.io/images/linkedin.png',
  };

  return (
    <a
      className={classnames('socialIcon', `socialIcon-${type}`, className)}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={icons[type]} alt={type} />
    </a>
  );
};

SocialIcon.propTypes = {
  type: oneOf([
    'facebook',
    'twitter',
    'instagram',
    'tiktok',
    'twitch',
    'youtube',
    'linkedin',
  ]),
  link: string,
};

export default SocialIcon;
