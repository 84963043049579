import { getResizedUrl, withPixelDensity } from 'utils';

function responsiveImageBackgroundStyle(props) {
  const { width, height, src, clamp = 1 } = props;
  const config = {
    width: width ? withPixelDensity(width) : undefined,
    height: height ? withPixelDensity(height) : undefined,
    clamp,
  };
  const resizedImageUrl = getResizedUrl(src, config);
  return {
    backgroundImage: `url(${resizedImageUrl})`,
  };
}

export default responsiveImageBackgroundStyle;
