import React from 'react';

import './ResponsiveDebugger.scss';

export default function ResponsiveDebugger(props) {
  if (process.env.NODE_ENV === 'development')
    return (
      <div id="ResponsiveDebugger">
        <div className="xs">xs</div>
        <div className="sm">sm</div>
        <div className="md">md</div>
        <div className="lg">lg</div>
        <div className="xl">xl</div>
      </div>
    );
  return null;
}
