import React, { useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiMenuLeft, mdiMenuRight } from '@mdi/js';
import classnames from 'classnames';

import { useEventListener } from 'hooks';

import './ReaderControl.scss';

const DURATION = 2500;
export default function ReaderControls(props) {
  const { isFirstPage, onClickLeft, onClickRight } = props;
  const [visible, setVisible] = useState(null);
  const timeout = useRef();
  const refLeft = useRef();
  const refRight = useRef();

  useEventListener(
    'mousemove',
    () => {
      setVisible('left');
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setVisible(null), DURATION);
    },
    refLeft
  );
  useEventListener(
    'mousemove',
    () => {
      setVisible('right');
      if (timeout.current) clearTimeout(timeout);
      timeout.current = setTimeout(() => setVisible(null), DURATION);
    },
    refRight
  );

  const controlStyle = !onClickLeft || !onClickRight ? { width: '100%' } : {};
  return (
    <div id="ReaderControl">
      {onClickLeft && (
        <div
          className="controls left clickable"
          ref={refLeft}
          onClick={onClickLeft}
          style={controlStyle}
        >
          <div
            className={classnames('icon-wrapper', {
              visible: visible === 'left',
            })}
          >
            <Icon
              path={mdiMenuLeft}
              className={classnames({ visible: visible === 'left' })}
            />
          </div>
        </div>
      )}
      {onClickRight && (
        <div
          className={classnames('controls right clickable', {
            hidden: isFirstPage,
          })}
          ref={refRight}
          onClick={onClickRight}
          style={controlStyle}
        >
          <div
            className={classnames('icon-wrapper', {
              visible: visible === 'right',
            })}
          >
            <Icon
              path={mdiMenuRight}
              className={classnames({ visible: visible === 'right' })}
            />
          </div>
        </div>
      )}
    </div>
  );
}
