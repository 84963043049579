import React from 'react';

import { useHistory } from 'hooks';

import ContinueReading from './ContinueReading';

const ContinueReadingConnected = props => {
  const { loading, data, refetch } = useHistory();

  return (
    <ContinueReading
      {...props}
      loading={loading}
      history={!loading ? data.me.history : null}
      refetch={refetch}
    />
  );
};

export default ContinueReadingConnected;
