import { useResponsive, useAppState, readerSettingsSelector } from 'hooks';

export default function useReaderSettings(config = {}) {
  const { is } = useResponsive();
  const [readerSettings, actions] = useAppState(readerSettingsSelector);

  const isMobile = !is('lg');
  const enforceMobileReader =
    isMobile && !['page', 'vertical'].includes(readerSettings.reader);
  const forceWebtoon = config.direction === 'webtoon';

  let reader = readerSettings.reader;
  if (enforceMobileReader) reader = 'vertical';
  if (forceWebtoon) reader = 'webtoon';
  return [
    {
      ...readerSettings,
      reader,
    },
    actions.setReaderSettingsAction,
  ];
}
