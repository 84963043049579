import React from 'react';
import { ResponsiveText } from 'UI';
import { PrismicText } from '@prismicio/react';

import { usePrismicDocumentsByType } from '@prismicio/react';

import './TeamPage.scss';
import { Footer } from 'Components';
import { trackHandler } from 'utils';

export default function TeamPage(props) {
  const [document, { state }] = usePrismicDocumentsByType('team-members', {
    orderings: { field: 'document.first_publication_date', direction: 'asc' },
  });

  const loading = state !== 'loaded';
  const team = !loading ? document.results : [];
  return (
    <>
      <div id="teamPage">
        <div className="header">
          <ResponsiveText type="display" size="large" bold>
            Qui sommes-nous ?
          </ResponsiveText>
          <div>
            <ResponsiveText size="large">
              Mangas.io est le fruit du travail de toute une équipe que nous
              t'invitons à découvrir ici !
            </ResponsiveText>
            <ResponsiveText size="large">
              Tu peux d'ailleurs échanger avec nous sur
              <a
                href="https://discord.gg/T3FAWnw"
                onClick={e => trackHandler(e, 'Discord Clicked')}
                className="link-discord"
              >
                Discord
              </a>
              !
            </ResponsiveText>
          </div>
        </div>
        <div className="team-pictures">
          {team.map(member => (
            <div className="card-wrapper">
              <div className="flip-card">
                <div className="card card-front">
                  <img
                    src={member.data.thumbnail.url}
                    alt={member.data.name}
                    height="250px"
                    width="250px"
                  />
                </div>
                <div className="card card-back">
                  <PrismicText field={member.data.funfact} />
                </div>
              </div>
              <div className="bottom-card">
                <div className="card-name">
                  <PrismicText field={member.data.name} />
                </div>
                <div className="card-position">
                  <PrismicText field={member.data.position} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
