import * as Sentry from '@sentry/browser';
import { useProfile } from 'hooks';

function useAlgoliaEffect() {
  const { profile, loading } = useProfile();
  if (process.env.REACT_APP_SENTRY_DSN && !loading && profile._id) {
    Sentry.configureScope(function(scope) {
      scope.setUser({
        id: profile._id,
        email: profile.email,
      });
    });
  }
}

export default useAlgoliaEffect;
