import React from 'react';
import './ApercuV2.scss';
import { CarrouselWrapper } from 'Components';

const ApercuV2 = props => {
  return (
    <div className="apercuV2-container">
      <CarrouselWrapper style={{ color: 'black' }}>
        <div>
          <img
            src="https://cdn.mangas.io/storybook/Discord/join.png"
            alt="test"
          ></img>
          <img
            src="https://cdn.mangas.io/storybook/Discord/join.png"
            alt="test"
          ></img>
          <img
            src="https://cdn.mangas.io/storybook/Discord/join.png"
            alt="test"
          ></img>
        </div>
      </CarrouselWrapper>
    </div>
  );
};

export default ApercuV2;
