import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { Bloc, Button, Text } from 'design-system';
import dayjs from 'dayjs';

import { useFeatureFlags, useProfile, useFlags } from 'hooks';
import { Mask } from 'UI';

import './Modal.scss';

let timeout;
export default function Modal() {
  const flag = useFeatureFlags(['modal_message']);
  const [display, setDisplay] = useState(false);
  const [config, setConfig] = useState({});
  const { profile } = useProfile();
  const { flag: disablePromo, loading } = useFlags('NO_PROMO', true);

  const hasFlag =
    !loading && flag.modal_message.enabled && flag.modal_message.value;

  useEffect(() => {
    if (hasFlag) {
      try {
        const config = JSON.parse(flag.modal_message.value);
        const displayTimestamp = parseInt(
          window.localStorage.getItem(config.id),
          10
        );
        let shouldDisplay,
          timeoutOver = true;

        if (config.timeout && displayTimestamp) {
          timeoutOver = dayjs(displayTimestamp)
            .add(config.timeout, config.unit)
            .isBefore(dayjs());
        } else if (displayTimestamp) {
          timeoutOver = false;
        }

        if (config.target) {
          shouldDisplay =
            timeoutOver && config.target === 'visitor'
              ? !profile.isActive
              : false;
        } else shouldDisplay = timeoutOver;

        setConfig(config);

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          setDisplay(shouldDisplay);
          if (shouldDisplay) window.localStorage.setItem(config.id, Date.now());
        }, 10000);
      } catch (e) {
        console.warn(`Couldn't retrieve ${flag}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFlag, flag, profile.isActive]);

  function toggleDisplay() {
    setDisplay(!display);
  }

  if (!hasFlag || !display || loading || disablePromo) return null;
  return (
    <Mask onClose={toggleDisplay} withOverlay>
      <div className="promo-modal">
        <Bloc
          className="promo-bloc"
          title={config.title}
          title_right={<Icon onClick={toggleDisplay} path={mdiClose} />}
        >
          <div className="modal-content">
            <img
              style={{ maxWidth: `${parseInt(config.image.width)}px` }}
              id="modal-img"
              src={config.image.url}
              alt={config.image.alt}
            />
            <Text className="promo-text" as="div">
              {config.subtitle}
              <div>{config.text}</div>
              {config.bold && (
                <div className="bold marginTop">{config.bold}</div>
              )}
              {config.small && (
                <div className="small marginTop">{config.small}</div>
              )}
            </Text>
            <Link to={config.cta} onClick={toggleDisplay}>
              <Button
                id="modal-btn"
                label={config.cta_label || "Profiter de l'offre"}
                variant="primary"
                size="large"
              />
            </Link>
          </div>
        </Bloc>
      </div>
    </Mask>
  );
}
