import { useProfile } from 'hooks';

function useFlags(flagName, defaultValue = false) {
  const { profile, loading } = useProfile();

  return {
    flag: loading
      ? defaultValue
      : Array.isArray(profile.featureFlags) &&
        profile.featureFlags.includes(flagName),
    loading: loading,
  };
}

export default useFlags;
