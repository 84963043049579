import React from 'react';

import { useAuth } from 'hooks';

import PrivateNavbar from './PrivateNavbar';
import PublicNavbar from './PublicNavbar';

function Navbar(props) {
  const { isAuthenticated, loading } = useAuth();
  if (loading) return null;
  if (isAuthenticated) return <PrivateNavbar {...props} />;
  return <PublicNavbar {...props} />;
}

export default Navbar;
