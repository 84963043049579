import React from 'react';

import './LtrContentWall.scss';

function LtrContentWall(props) {
  const { dismiss } = props;
  return (
    <div className="LtrContentWall" onClick={dismiss}>
      <div className="LtrContentWallPopIn">
        <img
          className="PopInImage"
          src="https://cdn.mangas.io/images/Ltr-2-min.svg"
          width="175"
          height="138"
          alt="Reading from left to right"
        />
        <p className="message">Lecture de gauche à droite</p>
      </div>
    </div>
  );
}

export default LtrContentWall;
