import { cloneElement } from 'react';
import { useAuth, useProfile } from 'hooks';

function StatusSwitch({ children }) {
  const { loading, isAuthenticated } = useAuth();
  const { profile } = useProfile();

  const elems = Array.isArray(children) ? children : [children];
  if (loading) return true;

  let currentStatus = null;
  if (isAuthenticated && profile?.isActive) {
    currentStatus = profile?.info?.status === 'invited' ? 'INVITED' : 'PAYING';
  } else if (isAuthenticated) {
    currentStatus = 'FREE';
  } else {
    currentStatus = 'GUEST';
  }
  return elems
    .filter(elem => elem.props.status.includes(currentStatus))
    .map((elem, index) =>
      cloneElement(elem, { currentStatus, key: elem.props.key || index })
    );
}

export default StatusSwitch;
