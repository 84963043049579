import React from 'react';
import classname from 'classnames';
import './Page.scss';

function Page({ className, id, children }) {
  return (
    <div className={classname(className, 'responsivePage')} id={classname(id)}>
      {children}
    </div>
  );
}
export default Page;
