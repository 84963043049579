import React from 'react';

const Icon = props => (
  <svg fill="none" {...props} viewBox="0 0 30 27">
    <title>{props.alt}</title>
    <path d="M.012.01h7.766L15 15.753 22.223.011h7.765v26.98h-6.29L23.659 9.9 17.33 24.297h-4.66L6.341 9.902V26.99H.012V.01z" />
    <path
      className="io"
      d="M22.506 5.119c.107 0 .197.08.197.188 0 .113-.09.199-.197.199a.193.193 0 01-.19-.2c0-.107.084-.187.19-.187zm1.363-3.253h.233v3.64h-.233v-3.64zm3.146 0a1.819 1.819 0 110 3.64 1.819 1.819 0 110-3.64zm0 .232c-.873 0-1.593.708-1.593 1.585s.72 1.59 1.593 1.59c.874 0 1.593-.713 1.593-1.59 0-.877-.72-1.585-1.593-1.585z"
    />
  </svg>
);

export default Icon;
