import React from 'react';
import { usePrismicDocumentsByType } from '@prismicio/react';
import { useFlags } from 'hooks';
import { BlogCard, CarrouselWrapper } from 'Components';
import './LastArticleCards.scss';

function LastArticleCards() {
  const [document, { state }] = usePrismicDocumentsByType('blog_post', {
    fetchLinks: ['author.name', 'author.avatar', 'author.role'],
    orderings: { field: 'document.first_publication_date', direction: 'desc' },
  });
  const loading = state !== 'loaded';
  const posts = !loading ? document.results : [];

  return (
    <>
      <div className="content-wrapper">
        <CarrouselWrapper preTitle="100% mangas !" title="Les actus du blog">
          {posts.slice(0, 10).map(post => (
            <div key={post.uid} className="BlogFlex_item">
              <BlogCard key={post.uid} post={post} />
            </div>
          ))}
          {!posts.length &&
            [1, 2, 3].map(post => (
              <div key={post} className="BlogFlex_item">
                <BlogCard />
              </div>
            ))}
        </CarrouselWrapper>
      </div>
    </>
  );
}

export default function LastArticleCardsWithFeatureFlags() {
  const { flag: noPromoFlag } = useFlags('NO_PROMO', true);
  return noPromoFlag ? null : <LastArticleCards />;
}
