import { createContext, useContext as nativeUseContext } from 'react';

const contexts = {
  MangaContext: createContext({}),
};

export default function useContext(contextName) {
  if (!contexts[contextName])
    throw new Error(`Context ${contextName} doesn't exist`);
  return {
    context: contexts[contextName],
    value: nativeUseContext(contexts[contextName]),
  };
}
