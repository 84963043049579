import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import classnames from 'classnames';

import './Pill.scss';

const Pill = props => {
  const { label, icon, color, background, bgColor, className, onClick } = props;

  let style = {};
  if (background) {
    style.background = background;
  } else if (bgColor) {
    style.backgroundColor = bgColor;
  } else {
    style.backgroundColor = `${color}35`;
  }
  return (
    <span
      className={classnames('Pill', className)}
      style={{ color }}
      onClick={onClick}
    >
      <span className="background" style={style}>
        {icon && <Icon path={icon} style={{ fill: color }} />}
        <span className="label">{label}</span>
      </span>
    </span>
  );
};

Pill.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

Pill.defaultProps = {
  icon: null,
  color: '#00aabb',
};

export default Pill;
