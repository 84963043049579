import React, { useState } from 'react';
import classnames from 'classnames';

import { useEventListener, useReaderSettings } from 'hooks';

import './ReaderProgressBar.scss';

export default function ReaderProgressBar(props) {
  const { scrollRef, rtl } = props;
  const [autoProgress, setProgress] = useState(0);
  const [{ reader }] = useReaderSettings();
  useEventListener(
    'scroll',
    e => {
      if (scrollRef && scrollRef.current) {
        if (['page', 'page_double', 'horizontal'].includes(reader)) {
          if (rtl) {
            setProgress(
              ((scrollRef.current.scrollWidth - e.target.scrollLeft) * 100) /
                e.target.scrollWidth
            );
          } else {
            setProgress(
              ((e.target.scrollLeft + window.innerWidth) * 100) /
                e.target.scrollWidth
            );
          }
        } else
          setProgress(
            ((e.target.scrollTop + scrollRef.current.clientHeight) * 100) /
              e.target.scrollHeight
          );
      }
    },
    scrollRef
  );
  return (
    <div className={classnames(['ReaderProgress', { reversed: rtl }])}>
      <div className="loading-progress" style={{ width: `100%` }} />
      <div className="current-progress" style={{ width: `${autoProgress}%` }} />
    </div>
  );
}
