import { useState, useEffect, useRef } from 'react';
import useEventListener from 'hooks/useEventListener';

export default function useComputedSizes() {
  const defaultValue = {
    width: 0,
    height: 0,
  };
  const ref = useRef();
  const [loaded, setLoaded] = useState(false);
  const [sizes, setSizes] = useState(defaultValue);

  function computeSizes(ref) {
    if (ref && ref.current) {
      setSizes({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
      setLoaded(true);
    }
  }

  useEffect(() => {
    computeSizes(ref);
    // eslint-disable-next-line
  }, [ref.current]);
  useEventListener('resize', () => computeSizes(ref));
  return [ref, sizes, loaded];
}
