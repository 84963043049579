import { useState } from 'react';
import { useEventListener } from 'hooks';

function getWindowSize() {
  return {
    vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    vh: Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    ),
  };
}

export default function useResponsive() {
  const [state, setState] = useState(getWindowSize());
  function resizeHandler() {
    setState(getWindowSize());
  }
  // const throttledHandler = throttle(resizeHandler, 100);
  useEventListener('resize', resizeHandler);

  const is = size => {
    switch (size) {
      case 'xs':
        return state.vw > 0;
      case 'sm':
        return state.vw >= 576;
      case 'md':
        return state.vw >= 768;
      case 'lg':
        return state.vw >= 992;
      case 'xl':
        return state.vw >= 1200;
      default:
        return false;
    }
  };
  const isMobile = () => !is('sm');
  const responsiveSelect = config => {
    const value = Object.entries(config).find(([key, value]) => is(key));
    return value ? value[1] : config.default;
  };

  return {
    ...state,
    is,
    isMobile,
    responsiveSelect,
  };
}
