import { useState, useEffect, useCallback } from 'react';

import useComputedSizes from './useComputedSizes';

export default function useRatio(ratio = 1, base = 'width', delay = 1000) {
  const [ref, sizes] = useComputedSizes();
  const [computedSize, setComputedSize] = useState(1);
  const repeat = 5;
  const computeSizes = useCallback(
    (counter = 0) => {
      if (counter > repeat) return;
      if (ref.current) {
        setComputedSize(Math.round(sizes[base] * ratio));
      } else {
        return setTimeout(() => computeSizes(counter + 1), delay);
      }
    },
    [base, delay, ratio, ref, sizes]
  );

  useEffect(() => {
    const timeout = computeSizes();
    return () => timeout && clearTimeout(timeout);
  }, [sizes, ratio, base, ref, computeSizes]);
  return [ref, computedSize, sizes];
}
