import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import Slider from 'rc-slider';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Icon from '@mdi/react';
import { mdiClose, mdiArrowLeft, mdiMenu } from '@mdi/js';

import { dayjs } from 'utils';
import { ReaderSettings } from 'Components';
import { BackLink } from 'UI';

import './CompactMenu.scss';

const styles = {
  handleStyle: {
    backgroundColor: '#E11F7B',
    borderColor: '#E11F7B',
    width: 20,
    height: 20,
    marginTop: -7,
  },
  railStyle: {
    backgroundColor: 'rgba(196,196,196,0.5)',
    height: 7,
  },
  trackStyle: {
    backgroundColor: '#E11F7B',
    height: 7,
  },
};

export default function CompactMenu(props) {
  const { title, currentPage, volumes, chapter, slug } = props;
  const history = useHistory();
  const navigate = history.push;
  const [isOpen, setIsOpen] = useState(false);
  const sliderRef = useRef(null);

  const pageCount = (chapter.pages || []).length;
  const [sliderValue, setSliderValue] = useState(currentPage + 1);

  useEffect(() => {
    if (sliderValue === currentPage + 1) return;
    const element = document.querySelector(`[data-index="${sliderValue - 1}"`);
    if (element) element.scrollIntoView();
    /*eslint-disable-next-line*/
  }, [sliderValue]);

  useEffect(() => {
    setSliderValue(currentPage + 1);
  }, [currentPage]);

  return (
    <div className={classnames('CompactMenu', { open: isOpen })}>
      <div className="reader-menu-content">
        <div className="chapterInfo">
          <BackLink
            fallback={`/lire/${slug}`}
            render={() => <Icon className="close-icon" path={mdiArrowLeft} />}
          />

          <p className="--title-s">{title}</p>
          <Select
            isSearchable={false}
            className="select chapter"
            classNamePrefix="custom"
            styles={{
              option: (provided, state) => {
                return {
                  ...provided,
                  opacity:
                    dayjs().isBefore(state.data.releaseDate) ||
                    state.data.isRead
                      ? 0.5
                      : 1,
                  pointerEvents: dayjs().isBefore(state.data.releaseDate)
                    ? 'none'
                    : 'all',
                };
              },
            }}
            value={{ value: chapter.number, label: chapter.title }}
            onChange={row => {
              navigate(`/lire/${slug}/${row.value}/1`);
            }}
            options={volumes.map(volume => ({
              label: `Tome ${volume.number}`,
              options: volume.chapters
                .filter(chapter => !chapter.isSeparator)
                .map(chapter => ({
                  value: chapter.number,
                  label: chapter.title,
                  isRead: chapter.isRead,
                  releaseDate: chapter.releaseDate,
                })),
            }))}
          />
          <div className="controls">
            <span className="page">{pageCount}</span>
            <Slider
              ref={sliderRef}
              reverse
              className="slider"
              min={1}
              max={pageCount}
              value={sliderValue}
              onChange={setSliderValue}
              onAfterChange={() => sliderRef.current.blur()}
              handleStyle={styles.handleStyle}
              railStyle={styles.railStyle}
              trackStyle={styles.trackStyle}
            />
            <span className="page">{sliderValue}</span>
          </div>
        </div>
        <ReaderSettings withToggle onClose={() => setIsOpen(false)} />
      </div>
      <div className="toggle-button" onClick={() => setIsOpen(!isOpen)}>
        <Icon className="icon" path={isOpen ? mdiClose : mdiMenu} />
      </div>
    </div>
  );
}
