import React from 'react';
import { Logo, MobileLogo } from 'UI';
import classnames from 'classnames';

import './Navbar.scss';
import { BrowserHistoryLink } from 'hooks';

const CaptiveNavbar = props => {
  const { className, children, cta } = props;

  return (
    <header className={classnames('Navbar PublicNavbar', className)}>
      <div className="side">
        <BrowserHistoryLink to="/">
          <Logo className="logo" alt="Mangas.io" />
          <MobileLogo className="logoMobile" alt="Mangas.io" />
        </BrowserHistoryLink>
      </div>
      <div className="center"></div>
      <div className="side right">
        <p className="is-hidden-mobile">
          <span>{children}</span>
        </p>
        {cta}
      </div>
    </header>
  );
};

export default CaptiveNavbar;
