import React from 'react';
import { Helmet } from 'react-helmet';
import { textEllipsis } from 'utils';

function SEO({ title, description, url, image }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta name="twitter:title" content={title} />}
      {title && <meta property="og:title" content={title} />}

      {description && (
        <meta name="description" content={textEllipsis(description, 160)} />
      )}
      {description && (
        <meta
          name="twitter:description"
          content={textEllipsis(description, 160)}
        />
      )}
      {description && (
        <meta
          property="og:description"
          content={textEllipsis(description, 160)}
        />
      )}

      {url && <meta property="og:url" content={url} />}

      {image && <meta property="og:image" content={image.url} />}
      {image && <meta name="twitter:image" content={image.url} />}
      {image && image.dimensions && (
        <meta property="og:image:width" content={image.dimensions.width} />
      )}
      {image && image.dimensions && (
        <meta property="og:image:height" content={image.dimensions.height} />
      )}
    </Helmet>
  );
}

export default SEO;
