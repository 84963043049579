import React, { useState, useEffect, useContext, useCallback } from 'react';

import { randomId } from 'utils';
import { identify, track, getAnonymousId } from 'utils';

import { getGA4ClientId } from 'utils/ga4';

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

function getImpersonateToken() {
  const { search, origin, pathname } = window.location;
  const match = search.match(/impersonate=([^&]+)/);
  if (match) {
    window.history.replaceState(null, '', `${origin}${pathname}`);
    return match[1];
  }
  return null;
}

function getCafeynToken() {
  const { search, origin, pathname } = window.location;
  const match = search.match(/sso_cafeyn=([^&]+)/);
  if (match) {
    window.history.replaceState(null, '', `${origin}${pathname}`);
    return match[1];
  }
  return null;
}

function getCafeynHubToken() {
  const { search, origin, pathname } = window.location;
  const match = search.match(/sso_cafeyn_hub=([^&]+)/);
  if (match) {
    window.history.replaceState(null, '', `${origin}${pathname}`);
    return match[1];
  }
  return null;
}

export function AuthProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(
    localStorage.getItem('mangas.io.token') || ''
  );

  const [clientId, setClientId] = useState(null);
  const [ga4ClientId, setGa4ClientId] = useState(null);
  const [segmentAnonymousId, setSegmentAnonymousId] = useState(null);

  useEffect(() => {
    if (isAuthenticated) setClientId(null);
    else {
      let clientId = localStorage.getItem('mangas.io.clientId');
      if (!clientId) {
        clientId = randomId();
        localStorage.setItem('mangas.io.clientId', clientId);
      }
      setClientId(clientId);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let impersonateToken = getImpersonateToken();
    let cafeynToken = getCafeynToken();
    let cafeynHubToken = getCafeynHubToken();
    if (impersonateToken) validateToken(impersonateToken);
    else if (cafeynToken) validateCafeynToken(cafeynToken, 'cafeyn');
    else if (cafeynHubToken) validateCafeynToken(cafeynHubToken, 'cafeyn_hub');
    else if (token) validateToken(token);
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof token === 'string' && token.length > 0) {
      localStorage.setItem('mangas.io.token', [token]);
    } else localStorage.removeItem('mangas.io.token');
  }, [token]);

  const fetchGa4ClientId = (() => {
    let pollCounter = 0;
    return async () => {
      if (ga4ClientId) return ga4ClientId;
      const id = await getGA4ClientId();
      if (id) {
        setGa4ClientId(id);
        return id;
      }
      if (pollCounter < 5) {
        pollCounter++;
        setTimeout(fetchGa4ClientId, 100 * pollCounter);
      }
    };
  })();

  const fetchSegmentAnonymousId = () => {
    if (segmentAnonymousId) return segmentAnonymousId;
    const id = getAnonymousId();
    setSegmentAnonymousId(id);
    return id;
  };

  const authFetch = useCallback(async (endpoint, options = {}) => {
    const anonymousId = getAnonymousId();
    const ga4ClientId = await getGA4ClientId();
    const headers = {
      ...options.headers,
      'x-segment-anonymous-id': anonymousId,
      'x-mangas-io-ga4-clientid': ga4ClientId,
    };

    const response = await fetch(process.env.REACT_APP_AUTH_URL + endpoint, {
      ...options,
      headers,
    });

    if (response.headers.has('x-mangasio-jwt'))
      setToken(response.headers.get('x-mangasio-jwt'));

    return response;
  }, []);

  const validateToken = useCallback(
    async token => {
      const res = await authFetch('/token_validation', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ token }),
      }).then(res => res.json());
      if (res.error) {
        setToken('');
        setIsAuthenticated(false);
      } else {
        setToken(token);
        setIsAuthenticated(true);
      }
      setLoading(false);
    },
    [authFetch]
  );

  const validateCafeynToken = useCallback(
    async (token, vendor) => {
      const res = await authFetch('/sso_validation', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ token, vendor }),
      }).then(res => res.json());
      if (res.error) {
        // TODO : toast d'erreur
        setToken('');
        setIsAuthenticated(false);
      } else if (res.jwt) {
        setToken(res.jwt);
        setIsAuthenticated(true);
      }
      setLoading(false);
    },
    [authFetch]
  );

  const login = useCallback(
    async (email, password, passCultureToken) => {
      const res = await authFetch('/login', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ email, password, passCultureToken }),
      }).then(res => res.json());
      if (res.error) {
        throw new Error(res.error);
      } else {
        const { user, token } = res;
        setToken(token);
        setIsAuthenticated(true);
        identify(user.sub, {
          email: user.email,
        });
      }
    },
    [authFetch]
  );
  const signup = useCallback(
    async payload => {
      const res = await authFetch('/signup', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
      if (res.error) {
        throw new Error(res.error);
      } else {
        const { user, token } = res;
        setToken(token);
        setIsAuthenticated(true);
        identify(user.sub, {
          email: user.email,
        });
      }
    },
    [authFetch]
  );
  const logout = useCallback(async () => {
    setToken('');
    setIsAuthenticated(false);

    track('User Signed Out');
  }, []);

  const validateInvite = useCallback(
    code => {
      return authFetch(`/invite/${code}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }).then(res => res.json());
    },
    [authFetch]
  );
  const validatePassCulture = useCallback(
    code => {
      return authFetch(`/pass_culture/${code}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }).then(res => res.json());
    },
    [authFetch]
  );
  const addInvite = useCallback(
    code => {
      return authFetch(`/invite/${code}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then(res => res.json());
    },
    [token, authFetch]
  );
  const addPassCulture = useCallback(
    code => {
      return authFetch(`/pass_culture/${code}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then(res => res.json());
    },
    [token, authFetch]
  );
  const validateReferral = useCallback(
    referralCode => {
      return authFetch(`/referral/${referralCode}`, {
        method: 'GET',
      }).then(res => res.json());
    },
    [authFetch]
  );
  const addReferralCodeToProfile = useCallback(
    referralCode => {
      return authFetch(`/referral/${referralCode}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(res => {
        if (res.status !== 200) {
          throw new Error('could not add referral code to profile');
        }
        return res.json();
      });
    },
    [token, authFetch]
  );
  const resetPassword = useCallback(
    email => {
      return authFetch('/reset_password', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
    },
    [authFetch]
  );

  const setNewPassword = useCallback(
    async payload => {
      const result = await authFetch('/update_password', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
      if (result.error) {
        throw new Error(result.error);
      } else return result;
    },
    [authFetch]
  );

  return (
    <AuthContext.Provider
      //elements accessible dans le context
      value={{
        loading,
        isAuthenticated,
        login,
        logout,
        signup,
        validateToken,
        validateInvite,
        validatePassCulture,
        validateReferral,
        addPassCulture,
        addInvite,
        addReferralCodeToProfile,
        resetPassword,
        setNewPassword,
        token,
        clientId,
        fetchGa4ClientId,
        fetchSegmentAnonymousId,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
