import React from 'react';
import {
  usePrismicDocumentByUID,
  PrismicText,
  PrismicRichText,
} from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { dayjs } from 'utils';
import { SEO } from 'Components';
import Icon from '@mdi/react';
import { mdiHome } from '@mdi/js';

import './PostPage.scss';

// TODO : Affichage du related_content
function PostPage() {
  const { slug } = useParams();

  const [document, { state }] = usePrismicDocumentByUID('blog_post', slug, {
    fetchLinks: ['author.name', 'author.avatar', 'author.role'],
  });
  const loading = state !== 'loaded';

  const {
    title,
    subtitle,
    body,
    type,
    last_publication_date,
    meta_description,
    author,
    featured_image,
  } = !loading ? document.data : {};
  const first_publication_date = !loading
    ? document.first_publication_date
    : null;

  const meta = {
    title: `Mangas.io | ${asText(title)}`,
    description: asText(meta_description),
    url: window.location.origin + window.location.pathname,
    image: featured_image,
  };

  const datePub = new Date(first_publication_date).toISOString();
  const dateMod = last_publication_date
    ? new Date(last_publication_date).toISOString()
    : datePub;

  return (
    <div id="PostPage">
      <Helmet
        script={[
          helmetJsonLdProp({
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: asText(title),
            image: featured_image ? featured_image.url : '',
            datePublished: datePub,
            dateModified: dateMod,
            author: {
              '@type': 'Person',
              name: author ? asText(author.data.name) : '',
            },
            publisher: {
              '@type': 'Organization',
              name: 'Mangas.io',
              logo: 'https://cdn.mangas.io/landing/logo-mini-min.png',
            },
          }),
        ]}
      />
      <SEO {...meta} />
      <main className={classNames('PostPage-container')}>
        <div
          className="featured_image skeleton-shape-bg"
          style={{
            backgroundImage: `url(${
              featured_image ? featured_image.url : null
            })`,
          }}
        />
        {!loading && (
          <div className="content-wrapper">
            <p className="breadcrumb">
              <Link to="/blog">Blog</Link>
              {' > '}
              <span>{type}</span>
              {' > '}
              <span>
                <PrismicText field={title} />
              </span>
            </p>

            <div className="PostPage-article">
              <h1 className="title">
                <PrismicText field={title} />
              </h1>

              {author.data && (
                <div className="author">
                  <img
                    className="author_avatar"
                    src={author.data.avatar.url}
                    alt={<PrismicText field={author.data.name} />}
                  />
                  <div>
                    <p>
                      <PrismicText field={author.data.name} />, 
                      <PrismicText field={author.data.role} />
                    </p>
                    <p className="datetime">
                      <time dateTime={first_publication_date}>
                        {dayjs(first_publication_date).format('LL')}
                      </time>
                    </p>
                  </div>
                </div>
              )}

              <h2 className="subtitle">
                <PrismicText field={subtitle} />
              </h2>

              <div className="body">
                <PrismicRichText field={body} />
              </div>

              {author.data && (
                <div className="author">
                  <img
                    className="author_avatar"
                    src={author.data.avatar.url}
                    alt={<PrismicText field={author.data.name} />}
                  />
                  <div>
                    <p>
                      <PrismicText field={author.data.name} />, 
                      <PrismicText field={author.data.role} />
                    </p>
                    <p className="datetime">
                      <time dateTime={first_publication_date}>
                        {dayjs(first_publication_date).format('LL')}
                      </time>
                    </p>
                  </div>
                </div>
              )}

              <Link className="home_link" to="/blog">
                <Icon path={mdiHome} />
                Aller à l'accueil du blog
              </Link>
            </div>
          </div>
        )}
        {loading && (
          <div className="content-wrapper">
            <p className="breadcrumb" style={{ width: '20%' }}>
              {' '}
            </p>

            <div className="PostPage-article">
              <p className="title">
                <span className="skeleton-text dark"></span>
                <span
                  className="skeleton-text dark"
                  style={{ width: '30%' }}
                ></span>
              </p>

              <div className="body">
                <p>
                  <span className="skeleton-text dark"></span>
                  <span className="skeleton-text dark"></span>
                  <span className="skeleton-text dark"></span>
                  <span className="skeleton-text dark"></span>
                  <span
                    className="skeleton-text dark"
                    style={{ width: '50%' }}
                  ></span>
                </p>

                <div className="block-img">
                  <div
                    className="img skeleton-shape dark"
                    style={{ paddingTop: '40%' }}
                  ></div>
                </div>

                <h2>
                  <span
                    className="skeleton-text dark"
                    style={{ width: '20%' }}
                  ></span>
                </h2>

                <p>
                  <span className="skeleton-text dark"></span>
                  <span className="skeleton-text dark"></span>
                  <span className="skeleton-text dark"></span>
                  <span className="skeleton-text dark"></span>
                  <span
                    className="skeleton-text dark"
                    style={{ width: '50%' }}
                  ></span>
                </p>
              </div>
            </div>
          </div>
        )}
      </main>

      {/* <div className="blogPage-content-wrapper">
        <p className="blogPostRelated">Ces articles pourraient vous intéresser :</p>
        <Grid
          className="BlogGrid"
          container
          direction="row"
          spacing={3}
          alignItems="stretch"
        >
          {[1,2,3].map(post => (
            <Grid key={post} className="BlogGrid_item" item xs={12} sm={6} md={4}>
              <BlogCard />
            </Grid>
          ))}
        </Grid>
      </div> */}
    </div>
  );
}

export default PostPage;
