import React from 'react';
import { Form } from 'UI';

import './Success.scss';

function Success({ children }) {
  return (
    <Form className="mio-form-success">
      <img
        className="coffee-success"
        src="https://cdn.mangas.io/images/coffee/voucher-success-min.png"
        alt=""
      />
      {children}
    </Form>
  );
}

export default Success;
