import React from 'react';

import './ProgressBar.scss';

const ProgressBar = props => {
  const { completion, className } = props;
  return (
    <div className={`ProgressBar ${className}`}>
      <span className="completion" style={{ width: `${completion}%` }}></span>
    </div>
  );
};

export default ProgressBar;
