import { useEffect } from 'react';
import { useFlagsmith, useFlags } from 'flagsmith/react';
import { useProfile } from 'hooks';

export default function useFeatureFlags(
  requiredFlags = [],
  requiredTraits = []
) {
  const flags = useFlags(requiredFlags, requiredTraits);
  const flagsmith = useFlagsmith();
  const { profile } = useProfile();

  useEffect(() => {
    if (profile._id) {
      // console.log(`[flagsmith] Identifying user : ${profile._id}`);
      flagsmith.identify(profile._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile._id || null]);

  return flags;
}
