import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { Page } from 'UI';
import { useQuery } from 'hooks';
import { CollectionHeader, MangaGrid, Footer } from 'Components';

import './CollectionPage.scss';

const GET_COLLECTION = gql`
  query getCollection($id: ID!) {
    featuredCollection(id: $id) {
      _id
      title
      description
      image {
        url
      }
      mangas {
        _id
        slug
        title
        description
        chapterCount
        thumbnail {
          url
        }
        categories {
          label
          level
        }
      }
    }
  }
`;

export default function CollectionPage(props) {
  const { id } = useParams();
  const {
    data = { featuredCollection: { mangas: [] } },
    loading,
  } = useQuery(GET_COLLECTION, { variables: { id } });
  if (loading) return null;
  return (
    <>
      <Page id="Collection">
        <CollectionHeader collection={data.featuredCollection} />
        <div className="mangaGrid">
          <MangaGrid mangas={data.featuredCollection.mangas} />
        </div>
      </Page>
      <Footer />
    </>
  );
}
