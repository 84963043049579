import React, { useEffect, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';
import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';

import { ChapterBeginDivider, ChapterEndDivider } from 'Components';
import { BlurImg, Button } from 'UI';
import { usePage, useReaderSettings, useEventListener } from 'hooks';
import { CustomErrorBoundary } from 'utils';

import './TouchReader.scss';

// let menuToggleTimer = null;
let pageReadTimer = null;

function getBoundingStart(element, reader) {
  const boundingRect = element.getBoundingClientRect();
  return ['vertical', 'webtoon'].includes(reader)
    ? boundingRect.top
    : boundingRect.left;
}
function getBoundingEnd(element, reader) {
  const boundingRect = element.getBoundingClientRect();
  return ['vertical', 'webtoon'].includes(reader)
    ? boundingRect.bottom
    : boundingRect.right;
}

function getScreenSize(element, reader) {
  return ['vertical', 'webtoon'].includes(reader)
    ? element.offsetHeight
    : element.offsetWidth;
}

function TouchReader(props) {
  const {
    currentPage,
    navigateTo,
    chapter,
    slug,
    manga,
    sendPageReadEvent,
    direction,
  } = props;
  const [{ reader }] = useReaderSettings({ direction });
  const scrollRef = useRef();

  // let firstTime = true;

  const [ratio, setRatio] = useState(window.innerWidth / window.innerHeight);

  useEventListener('resize', () => {
    setRatio(window.innerWidth / window.innerHeight);
  });

  const scrollTo = useCallback(selector => {
    const currentElem = document.querySelector(selector);
    if (currentElem)
      currentElem.scrollIntoView({
        alignToTop: true,
        behavior: 'instant',
      });
  }, []);

  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
  }

  useEffect(() => {
    setTimeout(() => {
      scrollTo(`[data-index="${currentPage}"]`);
    }, 0);
    /*eslint-disable-next-line*/
  }, [reader]);

  const pagesInViewPort = () => {
    if (scrollRef && scrollRef.current) {
      const halfScreen = getScreenSize(scrollRef.current) / 2;
      const elements = [...scrollRef.current.querySelectorAll('.page')];
      return elements
        .sort((a, b) => {
          return getBoundingStart(a, reader) - getBoundingStart(b, reader);
        })
        .filter(
          p =>
            getBoundingStart(p, reader) <= halfScreen &&
            getBoundingEnd(p, reader) + 60 >= halfScreen
        );
    }
  };

  function handleScroll() {
    const visiblePages = pagesInViewPort() || [];
    const currentPageElement = visiblePages.pop();
    if (currentPageElement) {
      const newCurrentPage = parseInt(currentPageElement.dataset['index']);

      if (newCurrentPage !== currentPage) {
        navigateTo(newCurrentPage);
      }
    }
  }

  // function handleCompactMenu() {
  //   if (firstTime) {
  //     firstTime = false;
  //     return;
  //   }
  //   const burgerButton = document.querySelector(
  //     '#ReaderMenu .CompactMenu .burger-button'
  //   );
  //   burgerButton.classList.add('hide');
  //   if (menuToggleTimer) clearTimeout(menuToggleTimer);
  //   menuToggleTimer = setTimeout(() => {
  //     burgerButton.classList.remove('hide');
  //   }, 2000);
  // }

  function onPageClick(e) {
    if (reader !== 'page') return;
    if (e.target.classList.contains('effect')) return; // click sur le bouton page suivante/précédente
    const pageWidth = window.innerWidth;
    const clickPosition = e.nativeEvent.x;

    const leftPageIndex =
      direction === 'ltr' ? currentPage - 1 : currentPage + 1;
    const rightPageIndex =
      direction === 'ltr' ? currentPage + 1 : currentPage - 1;
    if (clickPosition < pageWidth / 2) {
      scrollTo(`[data-index="${leftPageIndex}"]`);
    } else {
      scrollTo(`[data-index="${rightPageIndex}"]`);
    }
  }

  useEventListener(
    'scroll',
    () => {
      handleScroll();
      //NOTE: suppression pendant Angoulème
      // handleCompactMenu();
    },
    reader === 'vertical' ? window : scrollRef
  );
  useEffect(() => {
    const clearTimer = () => {
      if (pageReadTimer) clearTimeout(pageReadTimer);
    };
    clearTimer();
    pageReadTimer = setTimeout(() => {
      sendPageReadEvent(currentPage);
    }, 200);
    return clearTimer;
  }, [currentPage, sendPageReadEvent]);

  if (chapter.pages.length === 0)
    return (
      <div id="TouchReader">
        <div className="loading">
          <Icon className="--spin" path={mdiLoading} />
        </div>
      </div>
    );

  const reversePages = direction === 'rtl' && reader !== 'vertical';
  const pages = reversePages ? [...chapter.pages].reverse() : chapter.pages;

  return (
    <div
      id="TouchReader"
      ref={scrollRef}
      className={classnames(`reader-${reader}`, 'hide-scrollbar')}
      onClick={onPageClick}
    >
      <div
        className="separator"
        data-index={direction === 'ltr' ? -1 : pages.length}
      >
        <ChapterBeginDivider
          slug={slug}
          chapter={chapter}
          manga={manga}
          navigateTo={navigateTo}
          currentPage={currentPage}
          direction={direction}
        />
      </div>
      {pages.map((page, index) => (
        <PageBoundary
          key={page._id}
          page={page}
          index={reversePages ? pages.length - index - 1 : index}
          currentPage={currentPage}
          windowRatio={ratio}
        />
      ))}
      <div
        className="separator"
        data-index={direction === 'ltr' ? pages.length : -1}
      >
        <ChapterEndDivider
          slug={slug}
          chapter={chapter}
          manga={manga}
          navigateTo={navigateTo}
          currentPage={currentPage}
          direction={direction}
        />
      </div>
    </div>
  );
}

function Page(props) {
  const { page, index, currentPage, hasError } = props;
  const [reload, pageLoaded] = usePage(page._id);

  return (
    <div className="page" data-index={index}>
      <BlurImg
        alt={`page ${page.number}`}
        image={{ ...page.image, ...pageLoaded.image }}
        shouldLoad={index < currentPage + 5 && index > currentPage - 5}
        width={page.image.meta.width}
        height={page.image.meta.height}
        reload={reload}
        hasError={hasError}
        renderError={setError => (
          <div className="reload-button">
            <Button
              onClick={e => {
                e.stopPropagation();
                reload();
                setError(false);
              }}
              label="Recharger l'image"
              secondary
            />
          </div>
        )}
      />
    </div>
  );
}

function PageBoundary(props) {
  const handleError = error => {
    return <Page {...props} hasError={true} />;
  };
  return (
    <CustomErrorBoundary handleError={handleError}>
      <Page {...props} />
    </CustomErrorBoundary>
  );
}

export default TouchReader;
