import React from 'react';
import { Route } from 'react-router-dom';
import { SignupPage } from 'Pages';
import { useAuth, useProfile } from 'hooks';

export default function PrivateRoute({ component, ...props }) {
  const { isAuthenticated, loading } = useAuth();
  const { loadingProfile } = useProfile();
  const RouteWrapper = children => <Route {...props}>{children}</Route>;

  if (loading || loadingProfile) return RouteWrapper(null);
  if (!isAuthenticated) return RouteWrapper(<SignupPage />);

  return RouteWrapper(component);
}
