import { useEffect } from 'react';
import {
  useLocalStorage,
  useAuth,
  useProfile,
  useAppState,
  newsletterSubscriptionSelector,
} from 'hooks';

/*
 ** Vérifie si l'user s'inscrit a la newsletter
 ** met a jour le profil de l'utilisateur en fonction
 */
function useNewsletterEffect() {
  const { isAuthenticated } = useAuth();
  const { updateMe } = useProfile();

  const [newsletterSubscription, actions] = useAppState(
    newsletterSubscriptionSelector
  );
  const [newsletterSubscriptionHydration, persist] = useLocalStorage(
    'mio-newsletter-subscription'
  );

  // hydration
  useEffect(() => {
    if (newsletterSubscriptionHydration)
      actions.setNewsletterSubscriptionAction(newsletterSubscriptionHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(newsletterSubscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletterSubscription]);

  useEffect(() => {
    async function run() {
      try {
        await updateMe({
          variables: { input: { newsletter: newsletterSubscription } },
        });
        actions.setNewsletterSubscriptionAction(null);
      } catch (error) {
        console.error(error.message);
      }
    }
    if (isAuthenticated && newsletterSubscription !== null) run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, newsletterSubscription]);
}

export default useNewsletterEffect;
