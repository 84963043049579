import React, { useState, useRef } from 'react';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import classnames from 'classnames';
import { useSwipeable } from 'react-swipeable';

import useEventListener from 'hooks/useEventListener';
import './PageTurn.scss';

const DURATION = 2500;
let timeout;

export default function PageTurn(props) {
  const { onClickLeft, onClickRight, isFirstPage, isLastPage, manga } = props;
  const [visible, setVisible] = useState(null);
  const handlers = useSwipeable({
    onSwipedLeft: !isFirstPage && onClickRight,
    onSwipedRight: !isLastPage && onClickLeft,
  });

  const refLeft = useRef();
  const refRight = useRef();
  useEventListener(
    'mousemove',
    () => {
      setVisible('left');
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => setVisible(null), DURATION);
    },
    refLeft
  );
  useEventListener(
    'mousemove',
    () => {
      setVisible('right');
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => setVisible(null), DURATION);
    },
    refRight
  );

  useEventListener('keyup', e => {
    if (e.key === 'ArrowRight') !isFirstPage && onClickRight();
    else if (e.key === 'ArrowLeft') !isLastPage && onClickLeft();
  });

  return (
    <div className="PageTurn" {...handlers}>
      <div
        ref={refLeft}
        className={classnames('left clickable', {
          hidden: manga.direction === 'rtl' ? isLastPage : isFirstPage,
        })}
        onClick={onClickLeft}
      >
        <div
          className={classnames('icon-wrapper', {
            visible: visible === 'left',
          })}
        >
          <Icon
            path={mdiChevronLeft}
            className={classnames({ visible: visible === 'left' })}
          />
        </div>
      </div>

      <div
        ref={refRight}
        className={classnames('right clickable', {
          hidden: manga.direction === 'rtl' ? isFirstPage : isLastPage,
        })}
        onClick={onClickRight}
      >
        <div
          className={classnames('icon-wrapper', {
            visible: visible === 'right',
          })}
        >
          <Icon
            path={mdiChevronRight}
            // className={classnames({ visible: visible === 'right' })}
          />
        </div>
      </div>
    </div>
  );
}
