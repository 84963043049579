import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import './Trailer.scss';

function Trailer({
  trailer,
  playing,
  width,
  height,
  light,
  loading,
  wrapperElement: Wrapper = props => <div {...props} />,
  ...props
}) {
  const ref = useRef(null);

  const handleReady = () => {
    if (window.analytics && window.analytics.ready) {
      window.analytics.ready(() => {
        const player = ref.current?.getInternalPlayer()
        if (player) {
          var ytAnalytics = new window.analytics.plugins.YouTubeAnalytics(player, process.env.REACT_APP_YOUTUBE_API_KEY)
          ytAnalytics.initialize();
        }
      });
    }
  };

  if (loading) return <Wrapper />;
  if (!trailer) return null;
  return (
    <Wrapper>
      <ReactPlayer
        ref={ref}
        playing={playing}
        light={light}
        controls={false}
        height={height}
        width={width}
        url={trailer}
        onReady={handleReady}
      />
    </Wrapper>
  );
}

export default Trailer;