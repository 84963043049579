import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import * as snippet from '@segment/snippet';
import * as Sentry from '@sentry/browser';
import packageInfo from '../package.json';

import App from './App';
import { AuthProvider } from 'hooks';
import { browserHistory, trackPage } from 'utils';
import { SEO } from 'Components';

const { setCurrentPage } = browserHistory();

if (process.env.REACT_APP_SENTRY_DSN) {
  const options = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: `${packageInfo.version}-${packageInfo.versionName}`,
  };
  Sentry.init(options);
}

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const history = createBrowserHistory(window);
setCurrentPage(window.location.pathname);
trackPage(); // Trigger Segment page view
history.listen(path => {
  setCurrentPage(path.pathname);
  trackPage(); // Trigger Segment page view
});

const renderSnippet = () => {
  const opts = {
    host: process.env.REACT_APP_SEGMENT_HOST,
    useHostForBundles: true,
    apiKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    ajsPath: `/a.js/v1/${process.env.REACT_APP_SEGMENT_WRITE_KEY}/a.min.js`,
    load: false, // Use Axeptio to load Segment tools selectively
    page: false, // Use Axeptio to trigger page call
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <>
    <SEO
      title="Mangas.io | Lecture manga en ligne sans pub et légale"
      description="Nannniiii ?! Des milliers de chapitres de mangas à lire en ligne en illimité, sans pub et légalement sur Mangas.io ? Masaka !"
      url="https://www.mangas.io/"
      image={{
        url: 'https://cdn.mangas.io/images/Mangas.io_.png',
        dimensions: { width: 1028, height: 514 },
      }}
    />
    <Helmet>
      <script type="text/javascript" id="axeptio-script">
        {`
        window.axeptioSettings = {
          clientId: "${process.env.REACT_APP_AXEPTIO_PROJECT_ID}",
          cookiesVersion: "${process.env.REACT_APP_AXEPTIO_COOKIES_VERSION}",
        };
        
        (function(d, s) {
          var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
          e.async = true; e.src = "//static.axept.io/sdk-slim.js";
          t.parentNode.insertBefore(e, t);
        })(document, "script");
      `}
      </script>
      <script type="text/javascript" id="segment-script">
        {`${renderSnippet()}`}
      </script>
      <script type="text/javascript" id="axeptio-segment-script">
        {`
        window._axcb = window._axcb || [];
        window._axcb.push(function(sdk){
          sdk.on('cookies:complete', function(choices){
            analytics.load("${process.env.REACT_APP_SEGMENT_WRITE_KEY}", {
              obfuscate: true,
              integrations: {
                All: true,
                'Segment.io': choices.segment,
                'Google Analytics 4': choices.google_analytics,
                'Facebook Conversions API (Actions)': choices.facebook_conversion_tracking,
                'Affilae': choices.affilae,
              }
            });
            analytics.page();
          });
        });
      `}
      </script>
    </Helmet>
    <AuthProvider>
      <App history={history} />
    </AuthProvider>
  </>
);
