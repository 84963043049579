import React, { useState } from 'react';

import { Button } from 'UI';

import './SensitiveContentWall.scss';

import IconSensitiveContent from 'assets/sensitive-content.png';

function SensitiveContentWall(props) {
  const { back, dismiss } = props;
  const [
    DisableSensitiveContentWall,
    setDisableSensitiveContentWall,
  ] = useState(false);
  return (
    <div id="SensitiveContentWall">
      <div className="content-wrapper">
        <div className="box">
          <div className="box-header">
            <span className="box-title">Avertissement</span>
          </div>
          <div className="box-content">
            <img
              className=""
              src={IconSensitiveContent}
              alt=""
              width="125"
              height="125"
            />
            <p>
              <strong>Contenu sensible</strong>
            </p>
            <p>
              Cette série contient des images considérées comme sensibles et
              susceptibles de choquer ou offenser certains lecteurs.
            </p>
            <p>
              <span className="field">
                <label
                  htmlFor="DisableSensitiveContentWall"
                  className="checkbox"
                >
                  <input
                    type="checkbox"
                    id="DisableSensitiveContentWall"
                    onChange={e => {
                      setDisableSensitiveContentWall(e.target.checked);
                    }}
                    checked={DisableSensitiveContentWall}
                  />
                  <span className="check"></span>
                  <span className="check-label">
                    Je ne souhaite plus être averti
                  </span>
                </label>
              </span>
            </p>
            <div className="box-actions">
              <Button secondary label="Retour" onClick={back} />
              <Button
                primary
                label="Continuer"
                onClick={() => dismiss(DisableSensitiveContentWall)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SensitiveContentWall;
