import { useEffect, useState } from 'react';

import useComputedSize from './useComputedSizes';

export default function useEllipsis() {
  const [maxLineNumber, setMaxLine] = useState(10);
  const [ref, sizes] = useComputedSize();

  useEffect(() => {
    if (ref && ref.current) {
      const lineheight = parseInt(ref.current.dataset.lineheight);
      const computedLine = Math.floor(
        sizes.height / (isNaN(lineheight) ? 21 : lineheight)
      );
      setMaxLine(computedLine);
    }
  }, [ref, sizes]);
  return [
    ref,
    {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: maxLineNumber,
    },
  ];
}
