import React from 'react';
import { PrismicRichText, usePrismicDocumentsByType } from '@prismicio/react';
import { Page, ResponsiveText } from 'UI';
import { Footer, LandingFaq } from 'Components';
import { trackHandler } from 'utils';
import './Faq.scss';

export default function FaqPage() {
  const [document, { state }] = usePrismicDocumentsByType('faq', {});
  const loading = state !== 'loaded';
  const faqs = !loading ? document.results : [];
  return (
    <>
      <Page id="Faq">
        <div className="pageTitle">
          <ResponsiveText bold type="display" size="large">
            FAQ
          </ResponsiveText>
          <ResponsiveText type="display" size="small">
            Tu as des questions sur Mangas.io ? Les réponses se trouvent
            probablement ici !
          </ResponsiveText>
        </div>
        {faqs.map(faq => (
          <div className="faq-component">
            <PrismicRichText className="titleFaq" field={faq.data.faq_title} />
            <LandingFaq title={faq.faq_title} page_name={faq.uid} />
          </div>
        ))}
        <p className="footer">
          Tu n’as pas trouvé ta réponse ?{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://vendors065122.typeform.com/to/FFrWtp3W"
            onClick={e =>
              trackHandler(e, 'Contact Clicked', {
                type: 'support',
                source: 'faq',
              })
            }
          >
            Contacte-nous
          </a>
          .
        </p>
      </Page>
      <Footer />
    </>
  );
}
