import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

const BrowserHistory = React.createContext(null);

export default function useBrowserHistory() {
  const history = useContext(BrowserHistory);
  return history;
}

export function Provider({ children }) {
  const history = useHistory();
  return (
    <BrowserHistory.Provider value={history}>
      {children}
    </BrowserHistory.Provider>
  );
}

export function BrowserHistoryLink({ to, children, ...props }) {
  const browserHistory = useBrowserHistory();
  return (
    <span
      className="link clickable"
      onClick={e => {
        e.preventDefault();
        browserHistory.push(to, props);
      }}
    >
      {children}
    </span>
  );
}
