import { useEffect } from 'react';
import {
  useLocalStorage,
  useAuth,
  useProfile,
  useAppState,
  passCultureCodeSelector,
} from 'hooks';
import toast from 'react-hot-toast';

const successToast = toastId => {
  toast.success('Offre pass culture activée !', {
    id: toastId,
    duration: 5000,
  });
};

const errorToast = (toastId, error) => {
  toast.error(`Impossible d'activer l'offre pass culture : ${error}.`, {
    id: toastId,
    duration: 10000,
  });
};

function usePassCultureEffect() {
  const { isAuthenticated, addPassCulture } = useAuth();
  const { refreshProfile } = useProfile();
  const [passCultureCode, actions] = useAppState(passCultureCodeSelector);
  const [passCultureCodeHydration, persist] = useLocalStorage(
    'mio-pass-culture-code'
  );

  // hydration
  useEffect(() => {
    if (passCultureCodeHydration)
      actions.setPassCultureAction(passCultureCodeHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(passCultureCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passCultureCode]);

  useEffect(() => {
    async function run() {
      const toastId = toast.loading("Activation de l'offre pass culture");
      try {
        const { valid, error } = await addPassCulture(passCultureCode);
        actions.setPassCultureAction(null);
        if (valid) {
          if (window.gtag) window.gtag('event', 'pass_culture_activated_gtm');
          successToast(toastId);
          await refreshProfile();
        } else {
          console.error(error);
          errorToast(toastId, error);
        }
      } catch (error) {
        console.error(error);
        errorToast(toastId, error);
      }
    }

    if (isAuthenticated && passCultureCode) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, passCultureCode]);
}

export default usePassCultureEffect;
