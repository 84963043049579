import React from 'react';
import { arrayOf, string, shape, bool } from 'prop-types';
import classnames from 'classnames';

import './TagList.scss';

function TagList(props) {
  const { tags, small } = props;
  return (
    <ul className={classnames('TagList', { small })}>
      {tags.map(tag => (
        <li key={tag.label}>{tag.label}</li>
      ))}
    </ul>
  );
}

const tagShape = shape({
  label: string,
});

TagList.propTypes = {
  tags: arrayOf(tagShape),
  small: bool,
};

TagList.defaultProps = {
  tags: [],
  small: false,
};

export default TagList;
