import React from 'react';

const Icon = props => (
  <svg fill="none" {...props} viewBox="0 0 136 16">
    <title>{props.alt}</title>
    <path d="M13.74 15l-.02-9.28-4.6 7.68H7.96l-4.6-7.56V15H.88V1h2.14l5.56 9.28L14.04 1h2.14l.02 14h-2.46zm17.482-3.24h-7L22.842 15h-2.68l6.3-14h2.56l6.32 14h-2.72l-1.4-3.24zm-.86-2.04l-2.64-6.12-2.62 6.12h5.26zM51.722 1v14h-2.14l-7.72-9.48V15h-2.58V1h2.14l7.72 9.48V1h2.58zm15.471 6.84h2.46v5.58a7.823 7.823 0 01-2.52 1.32c-.96.307-1.946.46-2.96.46-1.426 0-2.713-.307-3.86-.92a6.983 6.983 0 01-2.7-2.58c-.653-1.093-.98-2.327-.98-3.7 0-1.373.327-2.607.98-3.7a6.84 6.84 0 012.7-2.56c1.16-.627 2.46-.94 3.9-.94 1.174 0 2.24.193 3.2.58a6.28 6.28 0 012.42 1.7l-1.64 1.6c-1.066-1.067-2.353-1.6-3.86-1.6-.986 0-1.866.207-2.64.62-.76.413-1.36.993-1.8 1.74-.426.747-.64 1.6-.64 2.56 0 .933.214 1.773.64 2.52a4.753 4.753 0 001.8 1.76c.774.427 1.647.64 2.62.64 1.094 0 2.054-.24 2.88-.72V7.84zm17.13 3.92h-7L75.943 15h-2.68l6.3-14h2.56l6.32 14h-2.72l-1.4-3.24zm-.86-2.04l-2.64-6.12-2.62 6.12h5.26zm13.26 5.48a10.78 10.78 0 01-3.14-.46c-1-.307-1.793-.713-2.38-1.22l.9-2.02c.574.453 1.273.827 2.1 1.12.84.28 1.68.42 2.52.42 1.04 0 1.814-.167 2.32-.5.52-.333.78-.773.78-1.32 0-.4-.146-.727-.44-.98-.28-.267-.64-.473-1.08-.62a24.3 24.3 0 00-1.8-.5c-1.067-.253-1.933-.507-2.6-.76a4.402 4.402 0 01-1.7-1.18c-.466-.547-.7-1.28-.7-2.2 0-.773.207-1.473.62-2.1.427-.64 1.06-1.147 1.9-1.52.853-.373 1.894-.56 3.12-.56.853 0 1.694.107 2.52.32.827.213 1.54.52 2.14.92l-.82 2.02a8.083 8.083 0 00-1.92-.82 7.181 7.181 0 00-1.94-.28c-1.026 0-1.793.173-2.3.52-.493.347-.74.807-.74 1.38 0 .4.14.727.42.98.293.253.66.453 1.1.6a24.3 24.3 0 001.8.5c1.04.24 1.893.493 2.56.76a4.214 4.214 0 011.7 1.18c.48.533.72 1.253.72 2.16 0 .773-.213 1.473-.64 2.1-.413.627-1.047 1.127-1.9 1.5s-1.893.56-3.12.56z" />
    <path
      d="M107.241 15.08a.86.86 0 01-.6-.24.883.883 0 01-.24-.62c0-.24.08-.44.24-.6a.86.86 0 01.6-.24c.227 0 .42.08.58.24.174.16.26.36.26.6s-.086.447-.26.62a.79.79 0 01-.58.24zM113.845 1h1.02v14h-1.02V1zm13.999 14.1c-1.374 0-2.614-.307-3.72-.92a6.997 6.997 0 01-2.62-2.56c-.627-1.08-.94-2.287-.94-3.62 0-1.333.313-2.54.94-3.62a6.848 6.848 0 012.62-2.54c1.106-.627 2.346-.94 3.72-.94 1.373 0 2.613.307 3.72.92a6.713 6.713 0 012.6 2.54c.64 1.08.96 2.293.96 3.64s-.32 2.56-.96 3.64a6.712 6.712 0 01-2.6 2.54c-1.107.613-2.347.92-3.72.92zm0-.94c1.173 0 2.233-.267 3.18-.8a5.922 5.922 0 002.24-2.2c.546-.947.82-2 .82-3.16 0-1.16-.274-2.207-.82-3.14a5.879 5.879 0 00-2.24-2.22c-.947-.533-2.007-.8-3.18-.8-1.174 0-2.24.267-3.2.8a5.865 5.865 0 00-2.24 2.22c-.547.933-.82 1.98-.82 3.14 0 1.16.273 2.213.82 3.16a5.908 5.908 0 002.24 2.2c.96.533 2.026.8 3.2.8z"
      fill="#ED1E79"
    />
  </svg>
);

export default Icon;
