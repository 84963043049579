import React, { useState, useEffect } from 'react';
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
} from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiGiftOutline } from '@mdi/js';

import { PublicRoute } from 'utils';
import { useAuth, useProfile } from 'hooks';

import CodeSelect from './CodeSelect';
import ValidateCode from './ValidateCode';

const ERRORS = {
  'Invalid code': 'Code invalide',
  'NOT FOUND': 'Code invalide',
  GONE: 'Code déjà utilisé',
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (ERRORS[error]) return ERRORS[error];
  return ERRORS.default;
}

export default function CodeSelectRouter() {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const { addInvite, addPassCulture } = useAuth();
  const { refreshProfile } = useProfile();

  useEffect(() => {
    setError(null);
  }, [location.pathname]);

  async function handleVoucher(code) {
    const result = await addInvite(code);
    if (!result.valid) return setError(getErrorMessage(result.error));
    await refreshProfile();
    history.push('/success/voucher');
  }
  async function handlePassCulture(code) {
    const result = await addPassCulture(code);
    if (!result.valid) return setError(getErrorMessage(result.error));
    await refreshProfile();
    history.push('/success/pass-culture');
  }
  return (
    <Switch>
      <PublicRoute exact path={match.path} component={<CodeSelect />} />
      <PublicRoute
        exact
        path={`${match.path}/carte-cadeau`}
        component={
          <ValidateCode
            icon={<Icon className="mio-form-icon" path={mdiGiftOutline} />}
            label="Code Cadeau"
            placeholder="ton code carte cadeau..."
            error={error}
            setError={setError}
            onSubmit={handleVoucher}
          />
        }
      />
      <PublicRoute
        exact
        path={`${match.path}/pass-culture`}
        component={
          <ValidateCode
            icon={
              <img
                alt="Logo du Pass Culture"
                className="mio-form-icon"
                src="https://cdn.mangas.io/images/pass-culture-min.svg"
                style={{
                  height: 'auto',
                  width: '80%',
                }}
              />
            }
            label="Code Pass-culture"
            placeholder="ton code pass-culture..."
            error={error}
            setError={setError}
            onSubmit={handlePassCulture}
          />
        }
      />
    </Switch>
  );
}
