import { useEffect } from 'react';
import { useLocalStorage, useAppState, readerSettingsSelector } from 'hooks';

function useReaderSettingsEffect() {
  const [readerSettings, actions] = useAppState(readerSettingsSelector);
  const [readerSettingsHydration, persist] = useLocalStorage(
    'mio-reader-settings'
  );

  // hydration
  useEffect(() => {
    if (readerSettingsHydration)
      actions.setReaderSettingsAction(readerSettingsHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(readerSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    readerSettings.reader,
    readerSettings.reducedMotion,
    readerSettings.quality,
    readerSettings.ignoreContentWarning,
    readerSettings.zoom,
  ]);
}

export default useReaderSettingsEffect;
