import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { PrismicText } from '@prismicio/react';
import { dayjs, CustomErrorBoundary } from 'utils';

import './BlogCard.scss';

function BlogCard({ className, post = {} }) {
  const history = useHistory();
  const navigate = history.push;
  const { featured_image = {}, title, meta_description, author, lien_externe } =
    post.data || {};
  const { first_publication_date, tags } = post;

  return (
    <article
      className={classnames('BlogCard', className)}
      onClick={() =>
        lien_externe?.url
          ? window.open(lien_externe.url, '_blank')
          : navigate(`/blog/${post.uid}`)
      }
    >
      <div className="featured_image skeleton-shape-bg">
        <div
          className="featured_image_bg"
          style={{
            backgroundImage: `url(${featured_image.url})`,
          }}
        />
      </div>
      {post.data && (
        <div className="content">
          <p className="type">{tags.join(' | ')}</p>
          <h3 className="title">
            <PrismicText field={title} />
          </h3>
          <p className="description">
            <PrismicText field={meta_description} />
          </p>

          <footer className="card_footer">
            <div className="author_avatar">
              <img
                className="author_avatar_img"
                src={author.data.avatar.url}
                alt={author.data.avatar.alt}
                width={author.data.avatar.dimensions.width}
                height={author.data.avatar.dimensions.height}
              />
            </div>
            <div className="author-info">
              <p className="author">
                <PrismicText field={author.data.name} />, 
                <PrismicText field={author.data.role} />
              </p>
              <p className="datetime">
                <time dateTime={first_publication_date}>
                  {dayjs(first_publication_date).format('LL')}
                </time>
              </p>
            </div>
          </footer>
        </div>
      )}
      {!post.data && (
        <div className="content">
          <p className="type">
            <span className="skeleton-text" style={{ width: '20%' }}></span>
          </p>
          <p className="title">
            <span className="skeleton-text"></span>
          </p>
          <p className="description">
            <span className="skeleton-text"></span>
            <span className="skeleton-text"></span>
            <span className="skeleton-text" style={{ width: '50%' }}></span>
          </p>

          <footer className="card_footer">
            <div className="author-info">
              <p className="author">
                <span
                  className="skeleton-text"
                  style={{ width: '10em' }}
                ></span>
              </p>
            </div>
          </footer>
        </div>
      )}
    </article>
  );
}

export default function BlogCardWrapper(props) {
  return (
    <CustomErrorBoundary handleError={() => null}>
      <BlogCard {...props} />
    </CustomErrorBoundary>
  );
}
