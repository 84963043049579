import React from 'react';

import { getResizedUrl, withPixelDensity } from 'utils';

function ResponsiveImage(props) {
  const { width, height, src, alt = '', ...imgProps } = props;
  const config = {
    width: width ? withPixelDensity(width) : undefined,
    height: height ? withPixelDensity(height) : undefined,
  };
  const resizedImageUrl = getResizedUrl(src, config);

  return <img alt={alt} src={resizedImageUrl} {...imgProps} />;
}

export default ResponsiveImage;
