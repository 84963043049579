import useReferralEffect from './effects/referralEffect';
import useNewsletterEffect from './effects/newsletterEffect';
import useVoucherEffect from './effects/voucherEffect';
import useAlgoliaEffect from '././effects/algoliaEffect';
import useSentryEffect from '././effects/sentryEffect';
import usePassCultureEffect from './effects/passCultureEffect';
import useSkipLandingEffect from './effects/skipLandingEffect';
import useReaderSettingsEffect from './effects/readerSettingsEffect';
import useProfileEffect from './effects/profileEffect';
import useFavoritesEffect from './effects/favoritesEffect';

function StateEffects() {
  useProfileEffect();
  useFavoritesEffect();
  useReferralEffect();
  useNewsletterEffect();
  useVoucherEffect();
  usePassCultureEffect();
  useAlgoliaEffect();
  useSentryEffect();
  useSkipLandingEffect();
  useReaderSettingsEffect();

  return null;
}
export default StateEffects;
