import React from 'react';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_SENTRY_DSN)
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        scope.setTag('full-page-error', 'false');
      });
  }

  render() {
    if (this.state.hasError) {
      if (!this.props.handleError) return null;
      return this.props.handleError(this.state.error);
    }
    return this.props.children;
  }
}
