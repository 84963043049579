import { useInView } from 'framer-motion';
import { useRef, cloneElement } from 'react';

function ViewportOnly({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return <div ref={ref}>{cloneElement(children, { isInView })}</div>;
}

export default ViewportOnly;
