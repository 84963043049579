import React from 'react';
import gql from 'graphql-tag';

import useQuery from 'hooks/useQuery';
import Highlight from './Highlight';

const imgFragment = `
    url
    pos_x
    pos_y
`;

const GET_HIGHLIGHTS = gql`
  query getHighlight($limit: Int) {
    featuredHighlights(limit: $limit) {
      _id
      title
      titleColor
      description
      banner {
        ${imgFragment}
      }
      bannerMobile: banner(target: MOBILE) {
        ${imgFragment}
      }
      subtitle
      startDate
      endDate
      pills
      manga {
        _id
        slug
        title
        thumbnail {
          url
        }
        logo {
          ${imgFragment}
        }
      }
    }
  }
`;

export default function HighlightConnected(props) {
  const { loading, data } = useQuery(GET_HIGHLIGHTS, {
    variables: { limit: 5 },
  });
  return (
    <Highlight
      loading={loading}
      highlights={data ? data.featuredHighlights : null}
    />
  );
}
