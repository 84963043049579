import React from 'react';
import gql from 'graphql-tag';

import { useQuery } from 'hooks';
import Preview from './Preview';

const GET_PREVIEW = gql`
  query getPreview {
    preview {
      _id
      skip
      limit
      backgroundImage {
        url
      }
      pages {
        _id
        image(type: DEFAULT) {
          url
          meta {
            ratio
            width
            height
          }
        }
        isDoublePage
      }
      manga {
        _id
        slug
        title
        direction
        thumbnail {
          url
        }
        chapterCount
        description
        categories {
          level
          label
        }
      }
      chapter {
        _id
        title
        copyright
        number
      }
    }
  }
`;

export default function PreviewConnected() {
  const { data, loading } = useQuery(GET_PREVIEW);
  if (loading || !data || !data.preview) return null;
  return <Preview {...data.preview} />;
}
