import dayjs from 'dayjs';

import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.locale('fr');

export default dayjs;
