import { MangaLine } from 'Components';
import { useHistory, useRecommend } from 'hooks';
import { shuffle, CustomErrorBoundary } from 'utils';

function MangaRecosLine() {
  const { loading, data } = useHistory();
  const history = data?.me.history || [];
  const myManga = history.length > 0 && shuffle(history)[0].manga;

  if (!myManga) return null;

  return <InnerMangaRecoLine manga={myManga} loading={loading} />;
}

function InnerMangaRecoLine({ manga }) {
  const { recommendations, loading } = useRecommend(manga._id);

  return (
    <MangaLine
      loading={loading}
      title={`Parce que tu as lu ${manga.title}`}
      mangas={recommendations.map(reco => ({
        ...reco,
        thumbnail: { url: reco.thumbnail },
      }))}
    />
  );
}

export default function MangaRecosLineShielded(props) {
  return (
    <CustomErrorBoundary>
      <MangaRecosLine {...props} />
    </CustomErrorBoundary>
  );
}
