import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

import { useQuery, usePage } from 'hooks';
import { CarrouselWrapper } from 'Components';
import { BlurImg } from 'UI';
import { CustomErrorBoundary, ViewportOnly } from 'utils';

import './RandomPages.scss';

const GET_RANDOM_PAGES = gql`
  query randomPages {
    pageSample(limit: 20) {
      _id
      slug
      image(type: THUMBNAIL) {
        meta {
          ratio
          width
          height
        }
      }
    }
  }
`;

function PageWrapper(props) {
  const { page } = props;
  const [loadPage, pageLoaded] = usePage(page._id);
  //eslint-disable-next-line
  const reload = useCallback(loadPage, []);
  if (pageLoaded === null) return null;
  return (
    <Link to={`/lire/${page.slug}`} className="page">
      <BlurImg image={{ ...page.image, ...pageLoaded.image }} reload={reload} />
    </Link>
  );
}

function PageWrapperBoundary(props) {
  const handleError = error => {
    return null;
  };
  return (
    <CustomErrorBoundary handleError={handleError}>
      <PageWrapper {...props} />
    </CustomErrorBoundary>
  );
}

function RandomPagesWrapper(props) {
  return (
    <ViewportOnly>
      <RandomPages {...props} />
    </ViewportOnly>
  );
}

function RandomPages(props) {
  const { data, loading } = useQuery(GET_RANDOM_PAGES, {
    notifyonNetworkStatusChange: true,
  });
  const { title, isInView = true } = props;

  if (loading || !data || !isInView || !data.pageSample) return null;

  return (
    <CarrouselWrapper className="RandomPages" title={title}>
      {data.pageSample.map(page => (
        <PageWrapperBoundary page={page} key={page._id} />
      ))}
    </CarrouselWrapper>
  );
}

export default RandomPagesWrapper;
