const HISTORY_SIZE = 5;
let history = [];

function setCurrentPage(page) {
  const readerRegex = RegExp('/lire/[^/]+/.+?|/abonnement|/signup');
  const latestPage = history[history.length - 1];
  // si on est dans le lecteur, on remplace l'historique au lieu de l'ajouter
  if (readerRegex.test(latestPage)) {
    history[history.length - 1] = page;
  } else {
    history.push(page);
  }

  history = history.slice(-HISTORY_SIZE);
}

function getPage(index) {
  return history[history.length + index - 1] || null;
}

function popPages(count) {
  history.splice(-count);
}

export default function browserHistory() {
  return { getPage, setCurrentPage, popPages };
}
