export const debounce = (fn, delay) => {
  let timerId;
  const debouncedFn = function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
  debouncedFn.cancel = function () {
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
  };
  return debouncedFn;
}
