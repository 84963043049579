import React from 'react';
import { Router } from 'react-router-dom';
import { ScrollToTop } from 'utils';

function RouterWithScrollTop({ children, ...props }) {
  return (
    <Router {...props}>
      <ScrollToTop />
      {children}
    </Router>
  );
}

export default RouterWithScrollTop;
