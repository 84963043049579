import React, { useState, useRef, useEffect } from 'react';
import Icon from '@mdi/react';
import {
  mdiNewspaperVariantMultipleOutline,
  mdiClose,
  mdiChevronLeft,
  mdiChevronRight,
  mdiOpenInNew,
} from '@mdi/js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import classnames from 'classnames';
import gql from 'graphql-tag';

import { dayjs, CustomErrorBoundary } from 'utils';
import { useQuery } from 'hooks';
import { Mask, AdminIcon } from 'UI';

import './MangaAffiliations.scss';

const GQL_GET_AFFILIATIONS = gql`
  query getAffiliations($mangaId: ID) {
    affiliations(mangaId: $mangaId) {
      _id
      provider
      url
      price
      volume {
        thumbnail {
          url
        }
        number
        chapterStart
        chapterEnd
        publicationDate
        releaseDate
      }
    }
  }
`;

function MangaAffiliationError(props) {
  return props.render(
    <div className="manga-affiliation --content-block">
      <h2 className="--title-s uppercase">
        <Icon path={mdiNewspaperVariantMultipleOutline} /> Acheter les tomes
        papier
      </h2>
      <div className="content">
        Impossible de charger les liens d'affiliation
      </div>
    </div>
  );
}

function MangaAffiliations(props) {
  const { manga, render } = props;
  const { data, loading } = useQuery(GQL_GET_AFFILIATIONS, {
    variables: {
      mangaId: manga._id,
    },
  });
  const [selectedVolumeIndex, setSelectedVolumeIndex] = useState(null);
  const scrollLockRef = useRef(null);

  useEffect(() => {
    if (selectedVolumeIndex) disableBodyScroll(scrollLockRef.current);
    else clearAllBodyScrollLocks();
    return clearAllBodyScrollLocks;
  }, [selectedVolumeIndex]);

  if (loading) return null;
  const { affiliations } = data;
  const sortedAffiliations = affiliations
    .filter(affiliation => !!affiliation.volume)
    .sort((a, b) => a.volume.number - b.volume.number);
  const selectedVolume = sortedAffiliations[selectedVolumeIndex];

  if (sortedAffiliations.length === 0) return null;

  return render(
    <div className="manga-affiliation --content-block">
      <h2 className="--title-s uppercase">
        <Icon path={mdiNewspaperVariantMultipleOutline} /> Acheter les tomes
        papier
      </h2>
      <div className="content">
        {sortedAffiliations.map((el, index) => (
          <div
            key={el._id}
            className={'volume-wrapper clickable'}
            onClick={() => setSelectedVolumeIndex(index)}
          >
            <div
              className="image-wrapper"
              style={{
                backgroundImage: `url(${el.volume.thumbnail.url})`,
              }}
            />
            <p className="volume-title --text">Tome {el.volume.number}</p>
            {(!el.volume.releaseDate ||
              dayjs(el.volume.releaseDate).isAfter(new Date())) && (
              <AdminIcon />
            )}
          </div>
        ))}
      </div>
      {selectedVolumeIndex !== null && (
        <Mask
          withOverlay
          ref={scrollLockRef}
          onClose={() => setSelectedVolumeIndex(null)}
        >
          <div className="modal">
            <Icon
              className="icon close clickable"
              path={mdiClose}
              onClick={() => setSelectedVolumeIndex(null)}
            />
            <div className="top">
              <Icon
                className={classnames('icon', {
                  clickable: selectedVolumeIndex >= 1,
                  disabled: selectedVolumeIndex < 1,
                })}
                path={mdiChevronLeft}
                onClick={() => {
                  if (selectedVolumeIndex >= 1)
                    setSelectedVolumeIndex(selectedVolumeIndex - 1);
                }}
              />
              <img src={selectedVolume.volume.thumbnail.url} alt="" />
              <Icon
                className={classnames('icon', {
                  clickable: selectedVolumeIndex < affiliations.length - 1,
                  disabled: selectedVolumeIndex >= affiliations.length - 1,
                })}
                path={mdiChevronRight}
                onClick={() => {
                  if (selectedVolumeIndex < affiliations.length - 1)
                    setSelectedVolumeIndex(selectedVolumeIndex + 1);
                }}
              />
            </div>
            <div className="bottom">
              <div className="row">
                <div className="info">
                  <p className="--subtitle">Série</p>
                  <p className="--text">{manga.title}</p>
                </div>
                <div className="info">
                  <p className="--subtitle">Volume</p>
                  <p className="--text">{selectedVolume.volume.number}</p>
                </div>
              </div>
              <div className="row">
                <div className="info">
                  <p className="--subtitle">Chapitres</p>
                  <p className="--text">
                    {selectedVolume.volume.chapterStart}-
                    {selectedVolume.volume.chapterEnd}
                  </p>
                </div>
                <div className="info">
                  <p className="--subtitle">Année de parution</p>
                  <p className="--text">
                    {selectedVolume.volume.publicationDate
                      ? dayjs(selectedVolume.volume.publicationDate).format(
                          'YYYY'
                        )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="row links">
                <div className="info">
                  <p className="--subtitle">Revendeur</p>
                  <p className="--text">
                    <a
                      className="link"
                      href={selectedVolume.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedVolume.provider} <Icon path={mdiOpenInNew} />
                    </a>
                  </p>
                </div>
                <div className="info">
                  <p className="--subtitle">Prix</p>
                  <p className="--text">{selectedVolume.price.toFixed(2)}€</p>
                </div>
              </div>
            </div>
          </div>
        </Mask>
      )}
    </div>
  );
}

export default function MangaAffiliationWrapper(props) {
  return (
    <CustomErrorBoundary
      handleError={() => <MangaAffiliationError {...props} />}
    >
      <MangaAffiliations {...props} />
    </CustomErrorBoundary>
  );
}
