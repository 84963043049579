import React from 'react';
import gql from 'graphql-tag';
import {
  CollectionBlock,
  CollectionDiscoveryConnected,
  FavCategoriesRecoLine,
  FlashMessage,
  Footer,
  HighlightConnected,
  LastArticleCards,
  MaListeLine,
  MangaRecosLine,
  NewReleasesConnected,
  NudgeBanner,
  PreviewConnected,
  PublisherCarrousel,
  QuoteConnected,
  RandomPages,
  RepriseInline,
  TrendingLine,
} from 'Components';
import { Page, StatusSwitch } from 'UI';
import { useQuery } from 'hooks';
import './HomePage.scss';

const GET_COLLECTIONS_LINES = gql`
  query getCollectionsLines($filter: FilterCollectionInput) {
    featuredCollections(filter: $filter) {
      _id
      title
      position
      mangas {
        _id
        slug
        title
        thumbnail {
          url
        }
        categories {
          label
          level
        }
      }
    }
  }
`;
function HomePage() {
  const { data, loading } = useQuery(GET_COLLECTIONS_LINES, {
    variables: { filter: { collectionType: 'MANGA_LINE' } },
  });
  const featuredCollections = loading ? [] : data.featuredCollections;

  return (
    <>
      <Page id="HomePage">
        <HighlightConnected />
        <FlashMessage
          location="home"
          render={children => <section id="flash-message">{children}</section>}
        />
        <StatusSwitch>
          <NudgeBanner status="GUEST|FREE" />
        </StatusSwitch>
        <NewReleasesConnected />
        <MaListeLine />
        <TrendingLine />
        <MangaRecosLine />
        <RepriseInline />
        <FavCategoriesRecoLine />
        <CollectionBlock
          position={0}
          collections={featuredCollections}
          loading={loading}
        />
        <CollectionDiscoveryConnected />
        <CollectionBlock
          position={1}
          collections={featuredCollections}
          loading={loading}
        />
        <PreviewConnected />
        <CollectionBlock
          position={2}
          collections={featuredCollections}
          loading={loading}
        />
        <LastArticleCards />
        <RandomPages title="Pages au hasard" subtitle="Crois-tu au destin?" />
        <PublisherCarrousel />
        <QuoteConnected />
      </Page>
      <Footer />
    </>
  );
}

export default HomePage;
