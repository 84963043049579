import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './LandingFeatures.scss';

const LandingFeatures = props => {
  useEffect(() => {
    AOS.init({
      once: true,
      anchorPlacement: 'top-center',
    });

    /*eslint-disable-next-line*/
  }, []);

  return (
    <div id="LandingFeatures">
      <div className="big-card type-1">
        <div
          className="big-card-content"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="illu-mobile custom-illu-preview-mobile">
            <img
              src="https://cdn.mangas.io/landing/feature-preview-mobile-min.png"
              width="420"
              height="280"
              alt=""
              className=""
            />
          </div>
          <div className="content">
            <h3 className="title">Lis tes mangas où et quand tu veux</h3>
            <p>
              Tes héro.ine.s de manga préféré.e.s seront toujours à tes côtés
              avec Mangas.io ! Utilise notre app iOS et Android sur mobile ou
              notre plateforme web sur ton grand écran pour faire le plein de
              lecture.
            </p>
          </div>
          <div className="illu custom-illu-preview">
            <img
              src="https://cdn.mangas.io/landing/feature-preview-min.png"
              width="643"
              height="453"
              alt=""
              className=""
            />
          </div>
        </div>
      </div>

      <div className="big-card type-2 reverse">
        <div className="big-card-content">
          <div className="illu-mobile">
            <img
              src="https://cdn.mangas.io/landing/feature-hd-mobile-min.png"
              width="325"
              height="233"
              alt=""
              className=""
            />
          </div>
          <div className="content">
            <h3 className="title">Profite d’un confort de lecture ultime</h3>
            <p>
              Notre lecteur a été pensé par des fans de manga pour des fans de
              manga. Fini les scans de mauvaise qualité, profite enfin de tes
              mangas en HD avec la traduction officielle. Lis sans publicité
              gênante et personnalise ton lecteur selon tes envies !
            </p>
          </div>
          <div className="illu">
            <img
              src="https://cdn.mangas.io/landing/feature-hd-min.png"
              width="442"
              height="310"
              alt=""
              className=""
            />
          </div>
        </div>
      </div>

      <div className="big-card type-3">
        <div className="big-card-content">
          <div className="illu-mobile custom-illu-preview-mobile">
            <img
              src="https://cdn.mangas.io/landing/feature-preview-mobile-three.png"
              width="420"
              height="280"
              alt=""
              className=""
            />
          </div>
          <div className="content">
            <h3 className="title">
              Découvre de nouvelles pépites en exclusivité
            </h3>
            <p>
              Découvre les derniers chapitres de tes mangas préférés en
              simultrad, en direct du Japon et de la Chine ! Accède également à
              de nombreux mangas disponibles en avant-première, en exclusivité
              et en prépublication gratuite sur Mangas.io !
            </p>
          </div>
          <div className="illu custom-illu-preview">
            <img
              src="https://cdn.mangas.io/landing/feature-preview-three.png"
              width="442"
              height="310"
              alt=""
              className=""
            />
          </div>
        </div>
      </div>

      {/* <div className="big-card type-3">
        <div className="big-card-content">
          <div className="illu-mobile">
            <img src="https://cdn.mangas.io/landing/feature-offline-mobile-min.png" width="325" height="318" alt="" className="" />
          </div>
          <div className="content">
            <Pill className="big-card-pill" label="Bientôt disponible" color="white" />
            <h3 className="title">
              Accédez à vos mangas sans connexion
            </h3>
            <p>
              Notre application mobile vous permet de télécharger vos mangas préférés (par tome ou par chapitre) pour les lire sans internet. Vos mangas hors-ligne garantissent des voyages plus stimulants !
            </p>
          </div>
          <div className="illu">
            <img src="https://cdn.mangas.io/landing/feature-offline-min.png" width="373" height="364" alt="" className="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LandingFeatures;
