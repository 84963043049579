import React, { useState } from 'react';
import gql from 'graphql-tag';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight, mdiOpenInNew } from '@mdi/js';
import { Link } from 'react-router-dom';
import { useQuery } from 'hooks';

import {
  CustomErrorBoundary,
  withPixelDensity,
  getResizedUrl,
  trackHandler,
} from 'utils';
import './Affiliation.scss';

const GQL_GET_AFFILIATIONS = gql`
  query getAffiliations($mangaId: ID) {
    affiliations(mangaId: $mangaId) {
      _id
      provider
      url
      price
      volume {
        thumbnail {
          url
        }
        number
        chapterStart
        chapterEnd
        publicationDate
        releaseDate
      }
    }
  }
`;

function Affiliation({
  manga,
  volume,
  tomevolume = 1,
  wrapperElement: Wrapper = props => <div {...props} />,
}) {
  const { data, loading } = useQuery(GQL_GET_AFFILIATIONS, {
    variables: {
      mangaId: manga._id,
    },
  });
  const [selectedVolume, setSelectedVolume] = useState(tomevolume);

  if (loading) return null;

  const { affiliations } = data;
  const sanitizedAffiliations = affiliations.filter(
    affiliation => affiliation.volume
  );
  if (sanitizedAffiliations.length === 0) return null;

  const affiliationsForCurrentVolume = sanitizedAffiliations.filter(
    affiliation => affiliation.volume.number === selectedVolume
  );
  // if (affiliationsForCurrentVolume.length === 0) return null;

  // const currentVolume = affiliationsForCurrentVolume[0].volume;
  const currentVolume = volume[selectedVolume - 1];

  function hasNextVolume() {
    return volume.some(volume => volume.number > selectedVolume);
  }
  function hasPreviousVolume() {
    return volume.some(volume => volume.number < selectedVolume);
  }

  return (
    <Wrapper>
      <div className="affiliation-bloc">
        <div className="row img-thumb">
          <Icon
            className="icon-selector"
            path={mdiChevronLeft}
            size={1}
            onClick={() => setSelectedVolume(selectedVolume - 1)}
            style={{
              visibility: hasPreviousVolume() ? 'visible' : 'hidden',
            }}
          />
          <div className="img-container">
            <img
              alt={`${manga.title} - Tome ${currentVolume.number}`}
              className="img-affiliation"
              src={getResizedUrl(currentVolume.thumbnail.url, {
                width: withPixelDensity(116),
              })}
            />
          </div>
          <Icon
            className="icon-selector"
            path={mdiChevronRight}
            size={1}
            onClick={() => setSelectedVolume(selectedVolume + 1)}
            style={{
              visibility: hasNextVolume() ? 'visible' : 'hidden',
            }}
          />
        </div>
        <div className="bottom">
          <div className="row">
            <div className="info">
              <p className="--subtitle">Volume</p>
              <p className="--text">{currentVolume.number}</p>
            </div>
            <div className="info">
              <p className="--subtitle">Chapitres</p>
              <p className="--text">
                {currentVolume.chapterStart}-{currentVolume.chapterEnd}
              </p>
            </div>
          </div>

          {affiliationsForCurrentVolume.length === 0 ? (
            <div className="row row-center">
              <p className="--text">Pas disponible</p>
            </div>
          ) : (
            <div className="row row-subtitles">
              <p className="--subtitle">REVENDEUR</p>
              <p className="--subtitle">PRIX</p>
            </div>
          )}
          {affiliationsForCurrentVolume.map(affiliation => (
            <div className="row row-links" key={affiliation._id}>
              <div className="info">
                <div className="links">
                  <p className="--text">
                    <Link
                      className="link"
                      onClick={e => {
                        window.open(affiliation.url);
                        trackHandler(e, 'Manga Purchase Clicked', {
                          id: affiliation._id,
                          title: manga.title,
                          slug: manga.slug,
                          provider: affiliation.provider,
                          url: affiliation.url,
                          thumbnail: affiliation.volume?.thumbnail?.url,
                          volume: affiliation.volume?.number,
                          price: affiliation.price,
                          currency: 'EUR',
                          publication_date: affiliation.volume?.publicationDate,
                          release_date: affiliation.volume?.releaseDate,
                        });
                      }}
                    >
                      {affiliation.provider}
                      <Icon path={mdiOpenInNew} />
                    </Link>
                  </p>
                </div>
              </div>
              <div className="info">
                <p className="--text">{affiliation.price.toFixed(2)}€</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}
function AffiliationError() {
  return null;
}

export default function AffiliationWrapper(props) {
  return (
    <CustomErrorBoundary handleError={() => <AffiliationError />}>
      <Affiliation {...props} />
    </CustomErrorBoundary>
  );
}
