import React from 'react';
import { Route } from 'react-router-dom';

export default function SwitchRoute({ children, unique = false, ...props }) {
  let filtered_children = children.filter(({ props }) => props.displayIf);
  if (unique) filtered_children = filtered_children.pop();
  const RouteWrapper = children => <Route {...props}>{children}</Route>;
  if (!filtered_children) return RouteWrapper(null);
  return RouteWrapper(filtered_children);
}
