import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import useMediaQuery from "../../hooks/useMediaQuery";

import './BlurImg.scss';

function useLoadedImage(src, display, reload, setError) {
  const img = useRef(new Image());
  const [url, setUrl] = useState();
  const errorCount = useRef(0);

  if (display && !url && src) {
    img.current.onload = () => {
      setUrl(src);
      if (setError) setError(false);
      errorCount.current = 0;
    };
    img.current.onerror = e => {
      if (errorCount.current < 5) reload();
      errorCount.current++;
      if (errorCount.current > 5 && setError) setError(true);
    };
    img.current.src = src;
  }

  useEffect(() => {
    if (display) {
      reload();
    }
    /*eslint-disable-next-line*/
  }, [display]);

  return url;
}

export default React.forwardRef(function BlurImg(props, ref) {
  const {
    image,
    style,
    className,
    reload = () => {},
    shouldLoad = true,
    hasError = false,
    renderError = () => null,
    ...imgProps
  } = props;
  const { width, height } = image.meta || {};

  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [error, setError] = useState(hasError);
  const url = useLoadedImage(
    image.url,
    !error && shouldDisplay,
    reload,
    setError
  );

  useEffect(() => {
    if (shouldLoad) setShouldDisplay(true);
  }, [shouldLoad]);

  const newStyle = {
    ...style,
    backgroundColor: '#ddd',
    aspectRatio: `${width}/${height}`
  };

  const minRatio = useMediaQuery(`(min-aspect-ratio: ${width}/${height})`);
  
  return (
    <div
      ref={ref}
      className={classnames({'min-ratio': minRatio}, 'image-wrapper', className)}
      style={newStyle}
    >
      <div className="overlay" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 1}
        height={height || 1}
        className="placeholder-svg"
      />
      {error && renderError(setError)}
      {url && <img className="image" src={url} alt="" {...imgProps} />}
    </div>
  );
});
