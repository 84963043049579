import React from 'react';
import classnames from 'classnames';
import { AppStoreButton } from 'UI';

import { trackHandler } from 'utils';
import './AppStoreButton.scss';

const AppStoreButtons = props => {
  const { className, passCulture, ...storeButtonProps } = props;

  return (
    <div
      className={classnames('AppStore-buttons', className)}
      {...storeButtonProps}
    >
      <a
        className="storeButtonLink"
        href="https://play.google.com/store/apps/details?id=io.mangas.app"
        onClick={e => trackHandler(e, 'Google Play Clicked')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppStoreButton type="android" />
      </a>
      <a
        className="storeButtonLink"
        href="https://apps.apple.com/fr/app/mangas-io-lecture-en-ligne/id1579236273"
        onClick={e => trackHandler(e, 'App Store Clicked')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppStoreButton type="ios" />
      </a>
      {passCulture && (
        <a
          className="storeButtonLink"
          href="https://passculture.app/recherche/resultats?query=%22mangas.io%22"
          onClick={e => trackHandler(e, 'Pass Culture Clicked')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppStoreButton type="passCulture" />
        </a>
      )}
    </div>
  );
};

AppStoreButtons.propTypes = {};

AppStoreButtons.defaultProps = {};

export default AppStoreButtons;
