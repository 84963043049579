import { useEffect } from 'react';
import {
  useLocalStorage,
  useAuth,
  useProfile,
  useAppState,
  voucherCodeSelector,
} from 'hooks';
import toast from 'react-hot-toast';

const ERRORS = {
  'Invalid code': 'Code invalide',
  'Code limit reached': 'Code déjà utilisé',
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (ERRORS[error]) return ERRORS[error];
  return ERRORS.default;
}

/*
 ** Vérifie si un code de carte cadeau est présent dans le
 ** local storage puis active la carte pour l'utilisateur
 ** et déclenche un toast de confirmation
 */
function useVoucherEffect() {
  const { isAuthenticated, addInvite } = useAuth();
  const { refreshProfile } = useProfile();
  const [voucherCode, actions] = useAppState(voucherCodeSelector);
  const [voucherCodeHydration, persist] = useLocalStorage('mio-voucher-code');

  // hydration
  useEffect(() => {
    if (voucherCodeHydration) actions.setVoucherAction(voucherCodeHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(voucherCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherCode]);

  useEffect(() => {
    async function run() {
      const toastId = toast.loading('Activation de la carte cadeau');
      try {
        const { valid, error } = await addInvite(voucherCode);
        actions.setVoucherAction(null);
        if (valid) {
          toast.success('Carte cadeau activée !', {
            id: toastId,
            duration: 5000,
          });
          await refreshProfile();
        } else {
          console.error(error);
          toast.error(
            `Impossible d'activer la carte cadeau : ${getErrorMessage(error)}.`,
            {
              id: toastId,
              duration: 10000,
            }
          );
        }
      } catch (error) {
        toast.error("Impossible d'activer la carte cadeau", {
          id: toastId,
          duration: 10000,
        });
        console.error(error.message);
      }
    }

    if (isAuthenticated && voucherCode) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, voucherCode]);
}

export default useVoucherEffect;
