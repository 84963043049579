import React from 'react';
import { useAuth, useHistory } from 'hooks';
import { MangaCardsHistory, MangaLine } from 'Components';
import { CustomErrorBoundary, repriseFormatter, dayjs } from 'utils';

function RepriseInline() {
  const { loading, data } = useHistory();
  const history = data?.me.history;
  if (loading) return <MangaLine tileSize="medium" loading />;
  if (history?.length === 0) return null;

  const filteredHistory = repriseFormatter(history).filter(
    h => !h.chapterIsFinished && dayjs(h.chapter.releaseDate).isBefore()
  );

  return (
    <MangaCardsHistory
      loading={loading}
      releases={filteredHistory}
      title="Mes lectures en cours"
    />
  );
}

export default function RepriseInlineShielded() {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) return null;
  return (
    <CustomErrorBoundary handleError={() => null}>
      <RepriseInline />
    </CustomErrorBoundary>
  );
}
