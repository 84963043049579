import React from 'react';
import { Link } from 'react-router-dom';

import './Form.scss';

export default function Form({ children, ...props }) {
  return (
    <div id="mio-form" {...props}>
      <header className="header">
        <Link to="/">
          <h1 className="title">
            <img
              className="logo"
              src="https://cdn.mangas.io/images/home/logo_white.svg"
              alt="Mangas.io"
            />
          </h1>
        </Link>
      </header>
      {children}
    </div>
  );
}
