import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  useMemo,
} from 'react';

const AppContext = createContext([]);

/*
 * DEFAULT VALUES
 */

export const defaultState = {
  profile: {
    bundles: [],
    access: 'OPEN',
    loading: true,
  },
  referralCode: null,
  voucherCode: null,
  passCultureCode: null,
  newsletterSubscription: null,
  skipLanding: false,
  favorites: null,
  readerSettings: {
    reader: 'vertical',
    quality: 'default',
    ignoreContentWarning: false,
    zoom: 1,
    reducedMotion: false,
  },
};

const favoritesReducer = (state, action) => {
  if (action.type === 'SET_FAVORITES')
    return {
      ...state,
      favorites: action.data,
    };
  if (action.type === 'ADD_FAVORITE')
    return {
      ...state,
      favorites: [...state.favorites, action.data],
    };
  if (action.type === 'REMOVE_FAVORITE') {
    return {
      ...state,
      favorites: state.favorites.filter(
        favorite => favorite._id !== action.data._id
      ),
    };
  }
  return state;
};

const profileReducer = (state, action) => {
  if (action.type === 'SET_PROFILE')
    return {
      ...state,
      profile: {
        ...action.data,
        bundles: action.data.bundles || [],
        access: (() => {
          const { _id, isActive } = action.data || {};
          if (!_id) return 'OPEN'; // not logged in
          if (isActive) return 'PAID';
          return 'FREE';
        })(),
      },
    };
  return state;
};

const configReducer = (state, action) => {
  if (action.type === 'SET_REFERRAL_CODE')
    return {
      ...state,
      referralCode: action.data,
    };
  if (action.type === 'SET_VOUCHER_CODE')
    return {
      ...state,
      voucherCode: action.data,
    };
  if (action.type === 'SET_PASS_CULTURE_CODE')
    return {
      ...state,
      passCultureCode: action.data,
    };
  if (action.type === 'SET_NEWSLETTER_SUBSCRIPTION')
    return {
      ...state,
      newsletterSubscription: action.data,
    };
  if (action.type === 'SET_SKIP_LANDING')
    return {
      ...state,
      skipLanding: action.data,
    };
  return state;
};

const readerSettingsReducer = (state, action) => {
  if (action.type === 'SET_READER_SETTINGS') {
    const { readerSettings } = state;
    const {
      reader,
      quality,
      ignoreContentWarning,
      zoom,
      reducedMotion,
    } = action.data;
    return {
      ...state,
      readerSettings: {
        quality: ['default', 'hd'].includes(quality)
          ? quality
          : readerSettings.quality,
        reader: ['vertical', 'horizontal', 'page', 'page_double'].includes(
          reader
        )
          ? reader
          : readerSettings.reader,
        ignoreContentWarning:
          typeof ignoreContentWarning === 'boolean'
            ? ignoreContentWarning
            : readerSettings.ignoreContentWarning,
        zoom: zoom ? parseFloat(zoom) : readerSettings.zoom,
        reducedMotion:
          typeof reducedMotion === 'boolean'
            ? reducedMotion
            : readerSettings.reducedMotion,
      },
    };
  }
  return state;
};

const composeReducers = (...reducers) => (state, action) => {
  return reducers.reduce((acc, reducer) => {
    return reducer(acc, action);
  }, state);
};

const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    composeReducers(
      profileReducer,
      configReducer,
      favoritesReducer,
      readerSettingsReducer
    ),
    defaultState
  );

  const actionCreator = useCallback(
    type => data => {
      dispatch({ type, data });
    },
    [dispatch]
  );

  const setVoucherAction = actionCreator('SET_VOUCHER_CODE');
  const setReferralAction = actionCreator('SET_REFERRAL_CODE');
  const setPassCultureAction = actionCreator('SET_PASS_CULTURE_CODE');
  const setNewsletterSubscriptionAction = actionCreator(
    'SET_NEWSLETTER_SUBSCRIPTION'
  );
  const setSkipLandingAction = actionCreator('SET_SKIP_LANDING');
  const setProfileAction = actionCreator('SET_PROFILE');
  const setFavoritesAction = actionCreator('SET_FAVORITES');
  const addFavoriteAction = actionCreator('ADD_FAVORITE');
  const removeFavoriteAction = actionCreator('REMOVE_FAVORITE');
  const setReaderSettingsAction = actionCreator('SET_READER_SETTINGS');
  const actions = useMemo(
    () => ({
      setVoucherAction,
      setReferralAction,
      setProfileAction,
      setPassCultureAction,
      setNewsletterSubscriptionAction,
      setSkipLandingAction,
      setFavoritesAction,
      addFavoriteAction,
      removeFavoriteAction,
      setReaderSettingsAction,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <AppContext.Provider value={[state, actions]}>
      {children}
    </AppContext.Provider>
  );
};

export const voucherCodeSelector = state => state.voucherCode;
export const referralCodeSelector = state => state.referralCode;
export const passCultureCodeSelector = state => state.passCultureCode;
export const newsletterSubscriptionSelector = state =>
  state.newsletterSubscription;
export const skipLandingSelector = state => state.skipLanding;
export const profileSelector = state => state.profile;
export const favoritesSelector = state => state.favorites;
export const readerSettingsSelector = state => state.readerSettings;

export const useAppState = (selector = s => s) => {
  const [state, actions] = useContext(AppContext);
  return [selector(state), actions];
};

export default AppStateProvider;
