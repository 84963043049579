import React, { useRef } from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';

import useEventListener from 'hooks/useEventListener';
import './Mask.scss';

const Mask = props => {
  const { withOverlay, absolute, closeOnScroll, className, ...childProps } = props;
  const ref = useRef();
  useEventListener(
    'wheel',
    () => {
      if (closeOnScroll) props.onClose();
    },
    ref
  );
  return (
    <div
      ref={ref}
      className={classNames('Mask', className, { withOverlay, absolute })}
      onClick={e => {
        if (e.target.classList.contains('Mask')) props.onClose();
      }}
      {...childProps}
    />
  );
};

Mask.propTypes = {
  withOverlay: bool,
  closeOnScroll: bool,
};

Mask.defaultProps = { withOverlay: false };

export default Mask;
