export async function getGA4ClientId() {
  return new Promise((resolve, reject) => {
    if (window.gtag) {
      const timeout = setTimeout(() => {
        resolve(null);
      }, 200);
      window.gtag(
        'get',
        process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
        'client_id',
        function(clientId) {
          clearTimeout(timeout);
          resolve(clientId);
        }
      );
    } else resolve(null);
  });
}
