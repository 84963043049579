export default function splitPagesByPairs(src, conf = {}) {
  const reverse = conf.direction === 'rtl';
  if (src.length > 0) {
    const chunk =
      src[0].isDoublePage || (src[1] && src[1].isDoublePage)
        ? [src[0]]
        : src.slice(0, 2);
    const chunkSize = chunk.length;
    return [
      reverse ? chunk.reverse() : chunk, // reverse so first page is on the left
      ...splitPagesByPairs(src.slice(chunkSize), conf),
    ];
  }
  return src;
}
