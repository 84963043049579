import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Text, Button } from 'design-system';
import AOS from 'aos';
import 'aos/dist/aos.css';
import qs from 'qs';
import { AppStoreButton, Form } from 'UI';
import { track } from 'utils';

export default function SubscriptionSuccess(props) {
  const { type } = useParams();
  const { onClick = () => {}, currentLocation } = props;
  const { session_id, name, price } = qs.parse(currentLocation?.search, {
    ignoreQueryPrefix: true,
  });
  const description = {
    free: <Text>Ton abonnement DÉCOUVERTE a bien été activé.</Text>,
    stripe: (
      <Text>
        Avec ton abonnement tu peux désormais profiter de{' '}
        <b>nouvelles fonctionnalités </b>et de{' '}
        <b>l'accès illimité au catalogue </b> : des milliers de mangas
        t'attendent !
      </Text>
    ),
    voucher: <Text>Ta carte cadeau a bien été activée.</Text>,
    'pass-culture': <Text>Ton abonnement pass culture a bien été activé.</Text>,
  };
  useEffect(() => {
    AOS.init({
      once: true,
      anchorPlacement: 'top-center',
    });

    /*eslint-disable-next-line*/
  }, []);
  useEffect(() => {
    if (session_id && name && price) {
      track('Order Completed', {
        order_id: session_id,
        products: [
          {
            product_id: name,
            price: price / 100,
            currency: 'EUR',
            quantity: 1,
          },
        ],
      });
    }
  }, [session_id, name, price]);

  return (
    <Form className="success-form">
      <Text type="display" size="small" className="success-title">
        Tu peux maintenant profiter pleinement de Mangas.io !
      </Text>
      {description[type]}
      <Text type="text" className="dl-app-text">
        N'oublie pas de télécharger l'application Mangas.io pour accéder à tout
        moment à tes mangas favoris
      </Text>
      <div className="app-ctas">
        <a
          className="app-cta"
          href="https://play.google.com/store/apps/details?id=io.mangas.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppStoreButton type="android" />
        </a>
        <a
          className="app-cta"
          href="https://apps.apple.com/fr/app/mangas-io-lecture-en-ligne/id1579236273"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppStoreButton type="ios" />
        </a>
      </div>
      <Button
        fullWidth
        className="success-button"
        variant="primary"
        label="Commencer à lire"
        onClick={onClick}
      />

      <img
        data-aos="fade-up"
        className="coffee-success"
        src="https://cdn.mangas.io/images/coffee/voucher-success-min.png"
        alt=""
      />
    </Form>
  );
}
