import React from 'react';

import { MangaLine } from 'Components';
import { shuffle } from 'utils/random';
import { range } from 'utils';

export default function CollectionBlock(props) {
  const { position, collections, loading } = props;

  if (loading) {
    return range(2).map(key => <MangaLine key={key} loading={true} />);
  }

  return collections
    .filter(collection => collection.position === position)
    .map(collection => {
      const { mangas, title, _id: key } = collection;
      const shuffledMangas = shuffle(mangas);
      return <MangaLine key={key} mangas={shuffledMangas} title={title} />;
    });
}
