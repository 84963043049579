import React from 'react';
import { withRouter, Redirect } from 'react-router';
import * as Sentry from '@sentry/browser';

import { AuthContext } from 'hooks';
import { ErrorPage } from 'Pages';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_SENTRY_DSN)
      Sentry.withScope(scope => {
        scope.setTag('full-page-error', 'true');
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname)
      this.setState({ hasError: false });
  }

  handleGraphqlErrors = errors => {
    console.info('HANDLING GRAPH ERROR');
    for (let error of errors) {
      switch (error.message) {
        case 'UNAUTHORIZED':
          return (
            <AuthContext.Consumer>
              {({ logout }) => {
                logout();
                return <Redirect to="/" noThrow />;
              }}
            </AuthContext.Consumer>
          );
        default:
          return <ErrorPage error={error} eventId={this.state.eventId} />;
      }
    }
    return <ErrorPage error={errors[0]} />;
  };

  handleNetworkError = error => {
    console.info('HANDLING NETWORK ERROR');
    switch (error.statusCode) {
      case 401:
        console.info('error Code 401');
        return (
          <AuthContext.Consumer>
            {({ logout }) => {
              logout();
              return <Redirect to="/" noThrow />;
            }}
          </AuthContext.Consumer>
        );
      default:
        return <ErrorPage error={error} code="NETWORK" />;
    }
  };

  render() {
    if (this.state.hasError) {
      const { graphQLErrors, networkError, ...error } = this.state.error;
      // console.log('ERROR STATE : ', { graphQLErrors, networkError, error });
      if (graphQLErrors && graphQLErrors.length)
        return this.handleGraphqlErrors(graphQLErrors);
      if (networkError) return this.handleNetworkError(networkError);
      return <ErrorPage error={error} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
