import gql from 'graphql-tag';
import { useQuery, useAuth } from 'hooks';

import { historyFragment } from 'gql';

const GET_HISTORY = gql`
  query getHistory($mangaId: ID) {
    me {
      _id
      history(mangaId: $mangaId) {
        ${historyFragment}
      }
    }
  }
`;

export default function useHistory(mangaId = null) {
  const { isAuthenticated } = useAuth();
  return useQuery(GET_HISTORY, {
    variables: { mangaId },
    skip: !isAuthenticated,
  });
}
