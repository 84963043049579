import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import classnames from 'classnames';

import { useSetRecoilState } from 'recoil';

import {
  usePage,
  useEventListener,
  useReaderSettings,
  useResponsive,
} from 'hooks';
import { throttle, splitPagesByPairs, CustomErrorBoundary } from 'utils';
import {
  ChapterBeginDivider,
  ChapterEndDivider,
  ReaderControl,
} from 'Components';
import { BlurImg, ReaderProgressBar, Button } from 'UI';

import currentReaderTypeState from 'states/current-reader-type.state';

import './UniversalReader.scss';

function PageWrapper(props) {
  const { currentPage, page, className, reader, zoom, hasError } = props;
  const [reload, pageLoaded] = usePage(page._id);
  const reloadCb = useCallback(() => {
    if (page.number > currentPage - 5 && page.number < currentPage + 5) {
      reload();
    }
  }, [currentPage, page.number, reload]);
  const ref = useRef();

  const [originalSizes, setOriginalSizes] = useState({});
  const { is } = useResponsive();
  const isMobile = !is('lg');
  const [style, setStyle] = useState({});
  const computeSize = useCallback(() => {
    if (ref && ref.current) {
      // const width = originalSizes
      //   ? originalSizes.width
      //   : ref.current.offsetWidth;
      const height = originalSizes
        ? originalSizes.height
        : ref.current.offsetHeight;
      return isMobile
        ? (reader === 'page' && {
            height: ref.current.offsetWidth / page.image.meta.ratio,
          }) ||
            null
        : {
            width: height && height * page.image.meta.ratio * zoom,
            height: height && height * zoom,
          };
    }
    /*eslint-disable-next-line*/
  }, [
    isMobile,
    originalSizes.height,
    originalSizes.width,
    page.image.meta.ratio,
    ref,
    zoom,
  ]);
  useEffect(() => {
    if (ref && ref.current)
      setOriginalSizes({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    /*eslint-disable-next-line*/
  }, [ref.current]);
  useEffect(() => {
    if (ref && ref.current) {
      setStyle(computeSize());
    }
    /*eslint-disable-next-line*/
  }, [computeSize, ref.current]);
  useEventListener('resize', () => {
    if (ref && ref.current) {
      setStyle(computeSize());
    }
  });

  return (
    <div
      className={classnames('page-wrapper', className, {
        'chapter-cover': page.isCover,
      })}
      id={`page-${page.number}`}
      data-index={page.number}
    >
      <BlurImg
        ref={ref}
        style={style}
        alt={`page ${page.number}`}
        image={{ ...page.image, ...pageLoaded.image }}
        shouldLoad={
          page.number > currentPage - 5 && page.number < currentPage + 5
        }
        reload={reloadCb}
        hasError={hasError}
        renderError={setError => (
          <div className="reload-button">
            <Button
              onClick={e => {
                e.stopPropagation();
                reloadCb();
                setError(false);
              }}
              label="Recharger l'image"
              secondary
            />
          </div>
        )}
      />
    </div>
  );
}

function PageWrapperBoundary(props) {
  const handleError = error => {
    return <PageWrapper {...props} hasError={true} />;
  };
  return (
    <CustomErrorBoundary handleError={handleError}>
      <PageWrapper {...props} />
    </CustomErrorBoundary>
  );
}

const doubleStrategy = {
  test: conf => ['horizontal', 'page_double'].includes(conf.readerType),
  exec: (...args) => splitPagesByPairs(...args),
};
const simpleStrategy = {
  test: conf => ['vertical', 'page', 'webtoon'].includes(conf.readerType),
  exec: pages => pages.map(p => [p]),
};

const reverseStrategy = {
  test: conf =>
    ['horizontal', 'page_double', 'page'].includes(conf.readerType) &&
    conf.direction === 'rtl',
  exec: pages => [...pages].reverse(),
};

const pageStrategies = [simpleStrategy, doubleStrategy, reverseStrategy];
const compose = (...funcs) => (init, params) =>
  funcs.reduce((acc, curr) => curr(acc, params), init);

const formatStrategy = {
  exec: (pages, conf) =>
    compose(
      ...pageStrategies
        .filter(strat => strat.test(conf))
        .map(strat => strat.exec)
    )(pages, conf),
};

function getBoundingStart(element, reader) {
  const boundingRect = element.getBoundingClientRect();
  return ['vertical', 'webtoon'].includes(reader)
    ? boundingRect.top
    : boundingRect.left;
}
function getBoundingEnd(element, reader) {
  const boundingRect = element.getBoundingClientRect();
  return ['vertical', 'webtoon'].includes(reader)
    ? boundingRect.bottom
    : boundingRect.right;
}

function getScreenSize(element, reader) {
  return ['vertical', 'webtoon'].includes(reader)
    ? element.offsetHeight
    : element.offsetWidth;
}

function PairWrapper(props) {
  const {
    pair,
    zoom,
    reader,
    currentPage,
    scrollToNext,
    scrollToPrevious,
    direction,
  } = props;
  const onClickLeft = direction === 'ltr' ? scrollToPrevious : scrollToNext;
  const onClickRight = direction === 'ltr' ? scrollToNext : scrollToPrevious;
  return (
    <div
      className={classnames('zoom-wrapper', {
        'contains-cover': pair[0] && pair[0].isCover,
      })}
    >
      <div
        id={`pair-${pair.map(p => p._id).join('-')}`}
        className={classnames('pair-wrapper snap-point', {
          pair: pair.length > 1,
        })}
      >
        {pair.map((page, i) => {
          return (
            <PageWrapperBoundary
              key={page._id}
              className={classnames({
                marginLeft: i === 0,
                pageLeft: pair.length > 1 && i === 0,
                pageRight: pair.length > 1 && i === 1,
              })}
              zoom={zoom}
              reader={reader}
              currentPage={currentPage}
              page={page}
            />
          );
        })}
      </div>
      {['page', 'page_double'].includes(reader) && (
        <ReaderControl onClickLeft={onClickLeft} onClickRight={onClickRight} />
      )}
    </div>
  );
}

export default function UniversalReader(props) {
  const {
    slug,
    chapter,
    navigateTo,
    manga,
    currentPage,
    sendPageReadEvent,
    zoom,
    direction,
  } = props;
  const { pages } = chapter;
  const scrollRef = useRef();
  const [{ reader, reducedMotion }, setSettings] = useReaderSettings({
    direction,
  });
  const { is } = useResponsive();
  const isMobile = !is('md');
  const [marginRight, setMarginRight] = useState(0);
  const setCurrentReaderType = useSetRecoilState(currentReaderTypeState);

  useEffect(() => {
    setCurrentReaderType(reader);
  }, [reader, setCurrentReaderType]);

  const resizeMargin = useCallback(() => {
    if (scrollRef.current) {
      const screenHeight = scrollRef.current.offsetHeight;
      const screenWidth = window.innerWidth;
      const dividerElem = document.getElementById('chapter-previous-divider');
      const dividerWidth = dividerElem ? dividerElem.offsetWidth : 0;

      let pagesWidth = 0;
      if (pages.length) {
        if (pages[0].isDoublePage || !pages[1]) {
          pagesWidth =
            (pages[0].image.meta.width * screenHeight) /
            pages[0].image.meta.height;
        } else {
          pagesWidth =
            ((pages[0].image.meta.width / 2) * screenHeight) /
              pages[0].image.meta.height +
            (pages[1].image.meta.width * screenHeight) /
              pages[1].image.meta.height;
        }
        const margin = (screenWidth - pagesWidth) / 2 - dividerWidth;
        setMarginRight(margin > 0 ? margin : 0);
      }
    }
  }, [pages, scrollRef]);
  const pagesInViewPort = useCallback(() => {
    if (scrollRef && scrollRef.current) {
      const halfScreen = getScreenSize(scrollRef.current) / 2;
      const elements = [...scrollRef.current.querySelectorAll('.page-wrapper')];
      return elements
        .sort((a, b) => {
          return getBoundingStart(a, reader) - getBoundingStart(b, reader);
        })
        .filter(
          p =>
            getBoundingStart(p, reader) <= halfScreen &&
            getBoundingEnd(p, reader) + 60 >= halfScreen
        );
    }
  }, [reader, scrollRef]);
  const scrollTo = useCallback(id => {
    const pageElement = document.getElementById(id);
    if (pageElement) pageElement.scrollIntoView({ inline: 'center' });
  }, []);

  const scrollToElement = useCallback(
    el => {
      el.scrollIntoView({
        behavior: reducedMotion ? 'instant' : 'smooth',
        inline: 'center',
      });
    },
    [reducedMotion]
  );

  useEventListener(
    'scroll',
    throttle(() => {
      const visiblePages = pagesInViewPort() || [];
      if (visiblePages[0]) {
        const [visiblePage] = visiblePages;
        const visiblePageIndex = parseInt(visiblePage.dataset.index);
        if (
          visiblePageIndex !== undefined &&
          visiblePageIndex !== currentPage
        ) {
          navigateTo(visiblePageIndex);
        }
      }
      visiblePages.forEach(visiblePage => {
        const visiblePageIndex = parseInt(visiblePage.dataset.index, 10);
        if (visiblePageIndex) sendPageReadEvent(visiblePageIndex);
      });
    }, 200),
    scrollRef
  );
  useEventListener('resize', resizeMargin);
  useEventListener('keyup', e => {
    if (['vertical', 'webtoon'].includes(reader)) {
      if (e.key === 'ArrowUp') {
        scrollToPrevious();
      } else if (e.key === 'ArrowDown') {
        scrollToNext();
      }
    } else {
      if (e.key === 'ArrowRight') {
        direction === 'ltr' ? scrollToNext() : scrollToPrevious();
      } else if (e.key === 'ArrowLeft') {
        direction === 'ltr' ? scrollToPrevious() : scrollToNext();
      }
    }
  });

  useEffect(resizeMargin, [pages, scrollRef, resizeMargin]);
  useEffect(() => {
    setTimeout(() => {
      scrollTo(`page-${currentPage}`);
    }, 0);
    /*eslint-disable-next-line*/
  }, [reader]);
  useEffect(() => {
    if (isMobile && !['page', 'vertical', 'webtoon'].includes(reader)) {
      setSettings({ reader: 'page' })();
    }
  }, [isMobile, reader, setSettings]);

  const formatPages = useCallback(
    () =>
      formatStrategy.exec(pages, { readerType: reader, direction: direction }),
    [reader, pages, direction]
  );
  const formattedPages = formatPages();

  const ltrSort = useCallback(
    (a, b) => getBoundingStart(b, reader) - getBoundingStart(a, reader),
    [reader]
  );
  const rtlSort = useCallback(
    (a, b) => getBoundingStart(a, reader) - getBoundingStart(b, reader),
    [reader]
  );
  const getSnapPoints = useCallback(() => {
    let sort = rtlSort;
    if (['vertical', 'webtoon'].includes(reader) || direction === 'ltr')
      sort = ltrSort;
    return [...scrollRef.current.querySelectorAll('.snap-point')].sort(sort);
  }, [direction, ltrSort, reader, rtlSort]);
  const getCurrentSnapPointIndex = useCallback(
    snapPoints => {
      const halfScreen = getScreenSize(scrollRef.current) / 2;
      const visibleSnapPoints = snapPoints.filter(el => {
        return (
          getBoundingStart(el, reader) <= halfScreen &&
          getBoundingEnd(el, reader) + 60 >= halfScreen
        );
      });
      return snapPoints.reduce((acc, el, index) => {
        if (visibleSnapPoints.find(v => v.id === el.id)) {
          if (!acc) return index;
          return index < acc ? index : acc;
        }
        return acc;
      }, null);
    },
    [reader]
  );

  const scrollToSnapPoint = useCallback(
    snapPoint => {
      const computedStyle = snapPoint ? getComputedStyle(snapPoint) : {};
      if (snapPoint && computedStyle.scrollSnapAlign !== 'none')
        scrollToElement(snapPoint);
      else if (snapPoint) scrollToElement(snapPoint.parentNode);
    },
    [scrollToElement]
  );

  const scrollToNext = useCallback(() => {
    const snapPoints = getSnapPoints();
    const currentSnapPointIndex = getCurrentSnapPointIndex(snapPoints);
    const nextSnapPoint = snapPoints[currentSnapPointIndex - 1];

    scrollToSnapPoint(nextSnapPoint);
  }, [getCurrentSnapPointIndex, getSnapPoints, scrollToSnapPoint]);

  const scrollToPrevious = useCallback(() => {
    const snapPoints = getSnapPoints();
    const currentSnapPointIndex = getCurrentSnapPointIndex(snapPoints);
    const previousSnapPoint = snapPoints[currentSnapPointIndex + 1];

    scrollToSnapPoint(previousSnapPoint);
  }, [getCurrentSnapPointIndex, getSnapPoints, scrollToSnapPoint]);

  return useMemo(() => {
    return (
      <div
        ref={scrollRef}
        id="UniversalReader"
        className={classnames(`reader-${reader}`)}
      >
        <ReaderProgressBar scrollRef={scrollRef} rtl={direction !== 'ltr'} />
        <ChapterBeginDivider
          slug={slug}
          chapter={chapter}
          manga={manga}
          navigateTo={navigateTo}
          currentPage={currentPage}
          className="snap-point"
          direction={direction}
        />
        {formattedPages.map(pair => (
          <PairWrapper
            key={`pair-${pair.map(p => p._id).join('-')}`}
            pair={pair}
            zoom={zoom}
            reader={reader}
            currentPage={currentPage}
            scrollToNext={scrollToNext}
            scrollToPrevious={scrollToPrevious}
            direction={direction}
          />
        ))}
        {['vertical', 'webtoon'].includes(reader) || (
          <div className="spacer-right" style={{ width: marginRight }} />
        )}
        <ChapterEndDivider
          manga={manga}
          slug={slug}
          chapter={chapter}
          navigateTo={navigateTo}
          currentPage={currentPage}
          className="snap-point"
          direction={direction}
        />
      </div>
    );
  }, [
    chapter,
    currentPage,
    formattedPages,
    marginRight,
    navigateTo,
    reader,
    scrollToNext,
    scrollToPrevious,
    slug,
    zoom,
    direction,
    manga,
  ]);
}
