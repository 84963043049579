import { useMemo } from 'react';
import { useProfile } from 'hooks';
import { mergeAlgoliaFilters, formatBundlesToAlgoliaFilters } from 'utils';

export function getBundleAccess(userBundles, ressourceBundleIds) {
  if (
    userBundles.some(
      bundle =>
        ressourceBundleIds.includes(bundle._id) && bundle.access === 'BLOCK'
    )
  )
    return 'BLOCK';
  if (
    userBundles.some(
      bundle =>
        ressourceBundleIds.includes(bundle._id) && bundle.access === 'READ'
    )
  )
    return 'READ';
  if (
    userBundles.some(
      bundle =>
        ressourceBundleIds.includes(bundle._id) && bundle.access === 'LIST'
    )
  )
    return 'LIST';
  return 'NONE';
}

function useBundles() {
  const { profile } = useProfile();
  const { bundles } = profile;

  const bundleAccess = (bundleIds = []) => getBundleAccess(bundles, bundleIds);

  const getAlgoliaBundlesFilters = useMemo(
    () => currentFilters => {
      const bundlesFilters = formatBundlesToAlgoliaFilters(bundles);
      return mergeAlgoliaFilters(currentFilters, bundlesFilters, 'AND');
    },
    [bundles]
  );

  return { bundleAccess, getAlgoliaBundlesFilters, bundles };
}

export default useBundles;
