import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { trackHandler } from 'utils';

import './Footer.scss';

export default function Footer(props) {
  const { className } = props;

  return (
    <footer id="Footer" className={classnames('minimalFooter', className)}>
      <Link to="/">
        <img
          className="mio-logo"
          src={process.env.PUBLIC_URL + '/favicon_512.png'}
          alt="Mangas.io Logo"
          width="112"
          height="112"
        />
      </Link>
      <nav className="footer-menu">
        <ul>
          <li>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="https://www.notion.so/Mentions-L-gales-de-Mangas-io-3eb755ecc9174e749995c2e582ae12df"
                  onClick={(e) => trackHandler(e, "Legal Notice Clicked")}
                >
                  Mentions Légales
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="https://www.notion.so/Politique-de-Protection-des-Donn-es-Caract-re-Personnel-de-Mangas-io-3ebd28535dd94df1bc2376d57fe0c111"
                  onClick={(e) => trackHandler(e, "Privacy Policy Clicked")}
                >
                  Politique de protection des données
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="https://www.notion.so/Conditions-G-n-rales-d-Utilisation-de-Mangas-io-bf445dbbcf514f218cbf31ac748c04e8"
                  onClick={(e) => trackHandler(e, "Terms of Service Clicked")}
                >
                  Conditions générales d'utilisation
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="https://www.notion.so/mangas/Politique-de-Cookies-de-Mangas-io-7a226eee039f4dca811672ead7dc53bb"
                  onClick={(e) => trackHandler(e, "Cookie Policy Clicked")}
                >
                  Politique de cookies
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </footer>
  );
}
