import { LinkedContent } from 'Components';
import { useLinkedContent } from 'hooks';

function LinkedContentConnected({ search, ...props }) {
  const documents = useLinkedContent(search);

  if (!documents || documents.length === 0) {
    return null;
  }

  return <LinkedContent documents={documents} {...props} />;
}

export default LinkedContentConnected;
