import { useEffect } from 'react';
import { useAuth, useFavorites } from 'hooks';

export default function useFavoritesEffect() {
  const { isAuthenticated } = useAuth();
  const { refreshFavorites } = useFavorites();

  useEffect(() => {
    if (isAuthenticated) {
      refreshFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
}
