import { useState } from 'react';

const emails = [
  'shin.seijuro@ojo.com',
  'hiruma.yoichi@devilbats.com',
  'gin.ichimaru@gotei13.com',
  'keigo.atobe@hyotei.com',
  'reiner.braun@mahr.com',
  'bruno.buccellati@passione.com',
  'dr.gero@redribbon.com',
  'sakuragi.hanamichi@shohoku.com',
  'nami@mugiwara.com',
  'trish.una@diavolo.com',
  'erza.scarlet@fairytail.com',
  'orochimaru.snek@akatsuki.com',
];
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function useMail() {
  const randomIndex = getRandomInt(0, emails.length);
  const [email] = useState(emails[randomIndex]);
  return email;
}
