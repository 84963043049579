import React from 'react';
import PropTypes from 'prop-types';

import './Template.scss';

const Template = props => {
  const { foo } = props;
  return (
    <div className="Template">
      <h1>{foo}</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
        quidem, quisquam minima molestiae totam nesciunt deserunt facere sint
        distinctio tenetur magnam eveniet nihil ullam quos neque, culpa
        temporibus id asperiores!
      </p>
    </div>
  );
};

Template.propTypes = {
  foo: PropTypes.string,
};

Template.defaultProps = {
  foo: 'Mangas.io ftw!',
};

export default Template;
