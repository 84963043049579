import aa from 'search-insights';
import { useProfile } from 'hooks';

function useAlgoliaEffect() {
  const { profile, loading } = useProfile();
  if (!loading && profile._id) {
    aa('setUserToken', profile._id);
  }
}

export default useAlgoliaEffect;
