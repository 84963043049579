import { useEffect } from 'react';
import { useLocalStorage, useAppState, skipLandingSelector } from 'hooks';

function useSkipLandingEffect() {
  const [skipLanding, actions] = useAppState(skipLandingSelector);
  const [skipLandingHydration, persist] = useLocalStorage('mio-skip-landing');

  // hydration
  useEffect(() => {
    if (skipLandingHydration)
      actions.setSkipLandingAction(skipLandingHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(skipLanding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipLanding]);
}

export default useSkipLandingEffect;
