import React from 'react';
import { string, arrayOf, shape, number } from 'prop-types';

import { useRatio, useEllipsis } from 'hooks';

import './Collection.scss';

const Collection = props => {
  const { title, description, thumbnails, backgroundColor } = props;
  const [descriptionRef, descriptionStyle] = useEllipsis();
  const [thumbnailRef, thumbnailHeight] = useRatio(1.5);

  return (
    <div className="Collection" style={{ backgroundColor }}>
      <h1 className="--subtitle">{title}</h1>
      <p className="--text" style={descriptionStyle} ref={descriptionRef}>
        {description}
      </p>
      <div className="thumbnails">
        {thumbnails.map((thumbnail, i) => (
          <div
            ref={thumbnailRef}
            className="thumbnail"
            key={i}
            style={{
              height: thumbnailHeight,
              backgroundImage: `url(${thumbnail.url})`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

const imageShape = shape({
  url: string.isRequired,
  x: number,
  y: number,
});

Collection.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  backgroundColor: string,
  thumbnails: arrayOf(imageShape),
};

Collection.defaultProps = {
  backgroundColor: '#333333',
  thumbnails: [],
};

export default Collection;
