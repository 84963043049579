import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useMail } from 'hooks';
import { track } from 'utils';

import './PreFooterTrial.scss';

export default function PreFooterTrial(props) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const emailPlaceholder = useMail();

  const handleSubmitFooter = async e => {
    e.preventDefault();

    track('Trial Form Submitted', {
      email: email,
    });

    history.push('/signup', { email });
  };

  return (
    <section id="PreFooterTrial" {...props}>
      <div className="section-container">
        <div className="content-wrapper">
          <form onSubmit={handleSubmitFooter} className="register-form">
            <div className="group-row">
              <input
                onChange={e => setEmail(e.target.value)}
                className="input"
                type="email"
                id="input-email"
                name="footer-trial-email"
                aria-label="E-mail"
                placeholder={emailPlaceholder}
                value={email.footer}
                required
              />
              <button type="submit" className="button is-primary is-fullwidth">
                Essayer gratuitement 1 semaine
              </button>
            </div>
            <p className="help text-white">
              Application mobile, simultrad, lecture live, nous avons encore de
              nombreuses idées à te faire découvrir : tu es prêt.e ?
            </p>
          </form>
        </div>
      </div>
    </section>
  );
}
