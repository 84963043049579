import React from 'react';
import { Link, useHistory as useNavigationHistory } from 'react-router-dom';
import { mdiFormatQuoteOpen, mdiFormatQuoteClose } from '@mdi/js';
import Icon from '@mdi/react';

import { CustomErrorBoundary } from 'utils';
import { track } from 'utils';
import './Quote.scss';

const Quote = props => {
  const { text, manga } = props;
  const navigation = useNavigationHistory();

  if (!manga) return null;

  const handleClick = async e => {
    e.preventDefault();

    track('Manga Quote Clicked', {
      manga_id: manga._id,
      title: manga.title,
      slug: manga.slug,
      description: manga.description,
      thumbnail: manga.thumbnail?.url,
      categories: manga.categories?.map(category => category.label),
      authors: manga.authors?.map(author => author.name),
      publishers: manga.publishers?.map(publisher => publisher.publisher?.name),
      volumes: manga.volumes?.length,
      quote: text,
    });

    navigation.push(`/lire/${manga.slug}`);
  };

  return (
    <div className="Quote">
      <span className="separator" />
      <p className="--subtitle">
        <Icon className="icon" path={mdiFormatQuoteOpen} />
        {text}
        <Icon className="icon" path={mdiFormatQuoteClose} />
      </p>
      <Link
        to={`/lire/${manga.slug}`}
        onClick={e => handleClick(e)}
        className="quote-link"
      >
        Découvrir l&apos;&oelig;uvre
      </Link>
    </div>
  );
};

function ShieldedQuote(props) {
  return (
    <CustomErrorBoundary>
      <Quote {...props} />
    </CustomErrorBoundary>
  );
}

export default ShieldedQuote;
