import { useEffect } from 'react';
import { useAuth, useProfile } from 'hooks';

export default function useProfileEffect() {
  const { isAuthenticated, loading } = useAuth();
  const { refreshProfile, clearProfile } = useProfile();
  useEffect(() => {
    if (isAuthenticated) {
      refreshProfile();
    } else if (!loading) {
      clearProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, loading]);
}
