import React from 'react';
import gql from 'graphql-tag';

import Quote from './Quote';
import { useQuery } from 'hooks';

const GET_QUOTE = gql`
  query getRandomQuote {
    quote {
      _id
      text
      manga {
        _id
        slug
        title
        description
        thumbnail {
          url
        }
        categories {
          label
        }
        authors {
          name
        }
        publishers {
          publisher {
            name
          }
        }
        volumes {
          number
        }
      }
    }
  }
`;

export default function QuoteConnected() {
  const { data, loading } = useQuery(GET_QUOTE);
  if (loading || !data || !data.quote) return null;
  return <Quote {...data.quote} />;
}
