function chapterIsFinished(historyElement) {
  return historyElement.page + 1 === historyElement.chapter.pageCount;
}

function pushNextChapter(historyElement) {
  // Si on est à la fin du chapitre et qu'il y a un chapitre suivant
  // On passe au chapitre suivant
  if (chapterIsFinished(historyElement) && historyElement.chapter.next) {
    return {
      ...historyElement,
      page: 0,
      chapter: historyElement.chapter.next,
    };
  }
  return historyElement;
}

function setHasNextChapter(historyElement) {
  return {
    ...historyElement,
    chapter: {
      ...historyElement.chapter,
      next: undefined,
    },
    hasNextChapter: !!historyElement.chapter.next,
  };
}

function setChapterIsFinished(historyElement) {
  return {
    ...historyElement,
    chapterIsFinished: chapterIsFinished(historyElement),
  };
}

export function repriseFormatter(history) {
  return history
    .map(pushNextChapter)
    .map(setChapterIsFinished)
    .map(setHasNextChapter)
    .sort((a, b) => {
      const diffdate = new Date();
      const distancea = Math.abs(diffdate - new Date(a));
      const distanceb = Math.abs(diffdate - new Date(b));
      return distanceb - distancea;
    });
}
