import React from 'react';
import classnames from 'classnames';
import './ResponsiveColumns.scss';

function cleanProps(elem, key) {
  if (!elem) return null;
  const { renderPosition, ...props } = elem.props;
  return <elem.type key={key} {...props} />;
}

function ResponsiveColumns({ invert, oneColumn, children = [] }) {
  const safeChildren = Array.isArray(children) ? children : [children];

  if (oneColumn)
    return (
      <div id="responsive-columns" className="oneColumn">
        {safeChildren.map(cleanProps)}
      </div>
    );

  return (
    <div id="responsive-columns" className={classnames({ invert })}>
      <div className="main">
        {safeChildren
          .filter(child => child && child.props.renderPosition === 'main')
          .map(cleanProps)}
      </div>
      <div className="sidebar">
        {safeChildren
          .filter(child => child && child.props.renderPosition === 'sidebar')
          .map(cleanProps)}
      </div>
    </div>
  );
}

export default ResponsiveColumns;
