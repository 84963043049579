import React from 'react';
import { string } from 'prop-types';
import { useAuth, useFavorites } from 'hooks';

import FavoriteButton from './FavoriteButton';

export default function FavoriteButtonConnected(props) {
  const { mangaId, ...buttonProps } = props;
  const {
    loading,
    loaded,
    addToFavorites,
    removeFromFavorites,
    isFavorite,
  } = useFavorites();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated || !loaded) return null;
  return (
    <FavoriteButton
      onAdd={() => addToFavorites({ variables: { mangaId } })}
      onRemove={() => removeFromFavorites({ variables: { mangaId } })}
      loading={loading}
      isFavorite={isFavorite(mangaId)}
      {...buttonProps}
    />
  );
}

FavoriteButtonConnected.propTypes = {
  mangaId: string.isRequired,
};
