import React from 'react';
import { Link } from 'react-router-dom';
import { browserHistory } from 'utils';

export default function BackLink({ fallback, render, ...props }) {
  const { getPage, popPages } = browserHistory();
  const previousPage = getPage(-1);
  const hasPreviousPage = !!previousPage;

  return (
    <Link onClick={() => popPages(2)} to={previousPage || fallback} {...props}>
      {render(hasPreviousPage)}
    </Link>
  );
}
