import React, { useState } from 'react';
import { Switch, MemoryRouter, useHistory, useParams } from 'react-router-dom';

import { Text } from 'design-system';

import { PublicRoute } from 'utils';

import StripeLoader from './StripeLoader';
import CodeSelectRouter from './CodeSelect.routes';
import SubscriptionSuccess from './SubscriptionSuccess';
import PricingTables from 'Components/PricingTables/PricingTables';

import './Subscription.scss';

function Subscription() {
  const history = useHistory();

  return (
    <div className="header-text">
      <div className="title-container">
        <Text className="secondaryText">
          Pour le <span className="spanText">prix d'un seul tome</span> par mois
        </Text>
        <Text type="display" size="large" bold>
          Lisez des mangas en illimité
        </Text>
        <Text className="secondaryText subtitle">
          Découvrez nos chapitres gratuits au catalogue puis débloquez la
          lecture illimitée grâce à un abonnement au prix d’un manga par mois.
        </Text>
      </div>
      <PricingTables
        onClick={priceId => history.push(`/stripe-loader/${priceId}`)}
      />
    </div>
  );
}

function StripeLoaderWrapper() {
  const { plan } = useParams();
  return <StripeLoader plan={plan} />;
}

export default function SubscriptionRouter(props) {
  const { onSubmit, initialRouterConfig } = props;
  const [error, setError] = useState(null);

  return (
    <div id="mio-subscription">
      <MemoryRouter initialEntries={initialRouterConfig}>
        <Switch>
          <PublicRoute
            exact
            path="/"
            component={
              <Subscription
                error={error}
                setError={setError}
                onFinish={onSubmit}
              />
            }
          />
          <PublicRoute
            path="/stripe-loader/:plan"
            component={<StripeLoaderWrapper />}
          />
          <PublicRoute path="/code" component={<CodeSelectRouter />} />
          <PublicRoute
            exact
            path="/success/:type"
            component={
              <SubscriptionSuccess
                currentLocation={props.currentLocation}
                onClick={onSubmit}
              />
            }
          />
        </Switch>
      </MemoryRouter>
    </div>
  );
}
