import { useEffect } from 'react';
import {
  useLocalStorage,
  useAuth,
  useProfile,
  useAppState,
  referralCodeSelector,
} from 'hooks';
import toast from 'react-hot-toast';

const successToast = toastId => {
  toast.success('Ton code de parrainage a été ajouté à ton profil', {
    id: toastId,
    duration: 5000,
  });
};

const errorToast = toastId => {
  toast.error("Impossible d'ajouter le code de parrainage", {
    id: toastId,
    duration: 10000,
  });
};

/*
 ** Vérifie si un code de parrainage est présent dans le global state
 ** puis ajoute le code de parrainage dans le profil et déclenche
 ** un toast de confirmation
 */
function useReferralEffect() {
  const { isAuthenticated, addReferralCodeToProfile } = useAuth();
  const { refreshProfile } = useProfile();
  const [referralCode, actions] = useAppState(referralCodeSelector);
  const [referralCodeHydration, persist] = useLocalStorage('mio-referral-code');

  // hydration
  useEffect(() => {
    if (referralCodeHydration) actions.setReferralAction(referralCodeHydration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // persistence
  useEffect(() => {
    persist(referralCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  useEffect(() => {
    async function run() {
      const toastId = toast.loading('Activation du code de parrainage');
      try {
        const { valid } = await addReferralCodeToProfile(referralCode);
        actions.setReferralAction(null);
        if (valid) successToast(toastId);
        else errorToast(toastId);
        await refreshProfile();
      } catch (error) {
        console.error(error.message);
        errorToast(toastId);
      }
    }

    if (isAuthenticated && referralCode) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, referralCode]);
}

export default useReferralEffect;
