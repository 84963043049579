import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './PricingCard.scss';

const PricingCard = props => {
  const {
    header,
    title,
    subtitle,
    chipText,
    link,
    linkText,
    version,
    selectPlan,
    className,
  } = props;

  return (
    <div id="PricingCard" className={classnames(version, className)}>
      <div className="background">
        <div className="card-header">
          <span>{header}</span>
        </div>
        <div className="card-content">
          <div>
            <span className="chip">{chipText}</span>
            {title}
            <p className="subtitle">{subtitle}</p>
          </div>
          {selectPlan === null ? (
            <a className="button" rel="noreferrer noopener" href={link}>
              {linkText}
            </a>
          ) : (
            <button className="button" onClick={selectPlan}>
              {linkText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  header: PropTypes.string,
  title: PropTypes.node,
  subtitle: PropTypes.string,
  chipText: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  version: PropTypes.oneOf(['yearly', 'monthly']),
  selectPlan: PropTypes.func,
};

PricingCard.defaultProps = {
  selectPlan: null,
  link: '',
};

export default PricingCard;
