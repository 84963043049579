import React from 'react';
import classnames from 'classnames';
import BackLink from 'UI/BackLink/BackLink';
import { Button } from 'design-system';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useResponsive } from 'hooks';
import './OnboardingBackground.scss';

export default function OnboardingBackground({
  children,
  className,
  isLarge,
  isSuccess,
  ...props
}) {
  const { is } = useResponsive();
  const isDesktop = is('lg');
  return (
    <div className={classnames('onboardingBackground', className)} {...props}>
      <div className={isSuccess ? 'successBg' : 'backgroundImage'}>
        <div className="blur" />
      </div>
      <svg
        style={{
          position: 'fixed',
          top: 0,
        }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xlink="http://www.w3.org/1999/xlink"
        svgjs="http://svgjs.dev/svgjs"
        viewBox="0 0 800 450"
        height="100%"
        width="100%"
        preserveAspectRatio="none"
        opacity="0.49"
      >
        <defs>
          <filter
            id="bbblurry-filter"
            x="-100%"
            y="-100%"
            width="400%"
            height="400%"
            filterUnits="objectBoundingBox"
            primitiveUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feGaussianBlur
              stdDeviation="58"
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              in="SourceGraphic"
              edgeMode="none"
              result="blur"
            ></feGaussianBlur>
          </filter>
        </defs>
        <g filter="url(#bbblurry-filter)">
          <ellipse
            className="ellipse"
            rx="108"
            ry="113.5"
            cx="79.16743607954545"
            cy="233.73103193803263"
            fill="hsla(313, 76%, 28%)"
          ></ellipse>
          <ellipse
            className="ellipse2"
            rx="108"
            ry="113.5"
            cx="258.45399613813925"
            cy="423.6811752319336"
            fill="hsla(197, 73%, 52%, 1.00)"
          ></ellipse>
          <ellipse
            className="ellipse2"
            rx="108"
            ry="113.5"
            cx="691.677001953125"
            cy="389.8179168701172"
            fill="hsla(265, 85%, 52%, 1.00)"
          ></ellipse>
          <ellipse
            className="ellipse"
            rx="108"
            ry="113.5"
            cx="480.0433876731179"
            cy="93.77490927956319"
            fill="hsla(321, 100%, 50%, 1.00)"
          ></ellipse>
        </g>
      </svg>
      {!isSuccess && (
        <div className="back-button">
          <BackLink
            fallback="/"
            render={() => (
              <Button
                variant={isDesktop ? 'secondary' : 'text'}
                icon={<Icon path={mdiArrowLeft} />}
                label={isDesktop && 'Retour'}
                className="BackButton"
                horizontalLg
              />
            )}
          />
        </div>
      )}
      <div className={classnames('wrapper', { largeWrapper: isLarge })}>
        {children}
      </div>
    </div>
  );
}
