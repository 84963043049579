import React from 'react';
import { Mask } from 'UI';
import classnames from 'classnames';

import './FloatingMenu.scss';

function FloatingMenu(props) {
  const { className, isOpen, ...childProps } = props;
  if (!isOpen) return null;
  return (
    <Mask {...childProps}>
      <div className={classnames('FloatingMenu', className)}>
        {props.children}
      </div>
    </Mask>
  );
}

function Header({ className, ...props }) {
  return (
    <div className={classnames('floatingmenu-header', className)} {...props} />
  );
}

function Body(props) {
  return <div className="floatingmenu-body" {...props} />;
}

export default Object.assign(FloatingMenu, {
  Header,
  Body,
});
