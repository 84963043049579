function identify(config) {
  const formData = new FormData();
  formData.append('userData', JSON.stringify(config));
  fetch('https://' + config.baseUrl + '/front/handleExternalLogin/', {
    method: 'POST',
    body: formData,
    credentials: 'include',
  });
}

function upvotySSO(profile) {
  const {
    REACT_APP_UPVOTY_BASE_URL,
    REACT_APP_UPVOTY_PUBLIC_KEY,
  } = process.env;

  if (
    !profile._id ||
    !REACT_APP_UPVOTY_BASE_URL ||
    !REACT_APP_UPVOTY_PUBLIC_KEY
  )
    return false;
  upvoty.identify({
    user: {
      id: profile._id,
      name: profile.email.split('@').shift(),
      email: profile.email,
    },
    baseUrl: REACT_APP_UPVOTY_BASE_URL,
    publicKey: REACT_APP_UPVOTY_PUBLIC_KEY,
  });
}

const upvoty = {
  identify,
  upvotySSO,
};

export default upvoty;
