let seed = null;

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function getSeed() {
  if (seed) return seed;
  seed = new Array(50).fill(null).map((_, index) => index);
  shuffleArray(seed);
  return seed;
}

export function shuffle(tab) {
  const seed = getSeed();
  return seed.map(index => tab[index]).filter(value => value);
}

export function randomId(length = 12) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
